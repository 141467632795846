<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="closeDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            HOURS
        </v-card-title>
        <v-card-text>
            <p>Number of hours in which this task was performed</p>
            <v-form v-model="valid">
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="hours"
                            prepend-icon="mdi-clipboard-text-clock-outline"
                            label="Hours *"
                            :rules="[rules.required, rules.value]"
                            type="number"
                            required
                        />
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn text color="red" @click="closeDialog">
                CANCEL
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                @click="saveHours"
                :loading="loading"
                :disabled="!valid"
            >
                SAVE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
    name: 'HoursForm',
    props: {
        taskHours: {
            type: Number,
            default: () => undefined,
        },
    },
    data: () => ({
        valid: false,
        loading: false,
        hours: 0,
        rules: {
            required: v => !!v || 'Required',
            value: v => Number(v) > 0 || 'invalid value',
        },
    }),
    mounted() {
        if (this.taskHours) {
            this.hours = this.taskHours
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        closeDialog() {
            this.$emit('closeHoursDialog')
        },
        async saveHours() {
            try {
                this.loading = true
                this.valid = false
                this.$emit('updateHours', this.hours)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style></style>
