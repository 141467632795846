<template>
    <div>
        <v-row
            v-if="loading"
            class="fill-height mt-3"
            align="center"
            justify="center"
        >
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </v-row>
        <v-row v-if="!loading" class="align-center font-weight-bold">
            <v-col>
                <span>Client Information</span>
            </v-col>
        </v-row>
        <v-row v-if="!loading" class="align-center mt-3 mb-4" no-gutters>
            <v-col
                cols="4"
                xl="2"
                lg="2"
                md="2"
                sm="2"
                class="d-flex align-center"
            >
                <span>Company</span>
            </v-col>
            <v-col
                v-if="!historyQuote && quote"
                cols="8"
                class="d-flex align-center"
            >
                <div v-if="textClient && quote.clientId">
                    <v-btn
                        :disabled="
                            quoteStatus !== 'OPEN' && quoteStatus !== 'APPROVAL'
                        "
                        @click="openDialogClient"
                        class="grey--text text--darken-1 text-body-2 text-capitalize"
                        text
                    >
                        {{ quote.client.name }}
                        <v-icon @click="openDialogClient">
                            mdi-menu-down
                        </v-icon>
                    </v-btn>
                </div>
                <v-autocomplete
                    :disabled="
                        quoteStatus !== 'OPEN' && quoteStatus !== 'APPROVAL'
                    "
                    v-if="changeClient || !quote.client"
                    flat
                    solo
                    hide-details
                    v-model="quote.clientId"
                    :items="quote.clients"
                    item-text="name"
                    item-value="id"
                    @change="selectClient"
                >
                </v-autocomplete>
            </v-col>
            <v-col v-if="historyQuote">
                <span v-if="quote">{{ quote.client.name }}</span>
            </v-col>
        </v-row>
        <v-row v-if="!loading" class="my-5" no-gutters>
            <v-col
                cols="2"
                xl="2"
                lg="2"
                md="2"
                sm="2"
                class="d-flex align-center"
            >
                <span>City</span>
            </v-col>
            <v-col v-if="quote" class="d-flex align-center">
                <span v-if="quote.client">{{ quote.client.city }}</span>
            </v-col>
        </v-row>
        <v-row v-if="!loading" class="my-4" no-gutters>
            <v-col
                cols="3"
                xl="2"
                lg="2"
                md="2"
                sm="2"
                class="d-flex align-center"
            >
                <span>Language</span>
            </v-col>
            <v-col v-if="quote" class="d-flex align-center">
                <v-avatar v-if="quote.client" size="33" class="me-3 ml-1">
                    <v-img
                        :src="
                            quote.client.language.toLowerCase() === 'english'
                                ? require('@/assets/usa_flag.png')
                                : require('@/assets/col_flag.png')
                        "
                    />
                </v-avatar>
                <span v-if="quote.client">{{ quote.client.language }}</span>
            </v-col>
        </v-row>
        <v-row v-if="!loading" class="my-2" no-gutters>
            <v-col
                cols="3"
                xl="2"
                lg="2"
                md="2"
                sm="2"
                class="d-flex align-top"
            >
                <span>Contacts</span>
            </v-col>
            <v-col
                cols="9"
                xl="10"
                lg="10"
                md="10"
                sm="10"
                v-if="quote"
                class="d-flex align-center flex-wrap"
                style="row-gap: 20px;"
            >
                <v-combobox
                    :disabled="quote.archive == true || historyQuote"
                    v-model="quote.dataContacts"
                    flat
                    :items="quote.client.contacts"
                    hide-details
                    item-text="name"
                    class="ml-2"
                    label="Search contact"
                    multiple
                    @change="addContact()"
                >
                </v-combobox>
            </v-col>
        </v-row>
        <v-row>
            <v-spacer />
            <v-col
                cols="9"
                xl="10"
                lg="10"
                md="10"
                sm="10"
                v-if="quote"
                class="d-flex align-center flex-wrap"
                style="row-gap: 20px; max-height: 500px; overflow-y: auto ;"
            >
                <v-card
                    flat
                    class="mr-1"
                    style="width: 100%;"
                    v-for="(contact, index) in quote.dataContacts"
                    :key="index"
                >
                    <div v-if="contact" class="d-flex">
                        <v-avatar color="grey lighten-4" size="34" class="mr-1">
                            <v-img
                                v-if="contact.profilePicURL"
                                :src="contact.profilePicURL"
                                :alt="contact.name"
                            />
                            <span v-else class="text-h6">
                                {{
                                    contact.name
                                        .split(' ')
                                        .map((n, i, a) =>
                                            i === 0 || i + 1 === a.length
                                                ? n[0]
                                                : null
                                        )
                                        .join('')
                                        .toUpperCase()
                                }}
                            </span>
                        </v-avatar>
                        <div
                            class="d-flex flex-column justify-space-around me-4"
                        >
                            <span class="font-weight-bold text-singleline">
                                {{ contact.name }}
                                {{ contact.role ? `(${contact.role})` : '' }}
                            </span>
                            <span class="text-caption text-singleline">
                                {{ contact.phone }}
                                {{
                                    contact.phone2 ? `- ${contact.phone2}` : ''
                                }}
                            </span>
                            <span class="text-caption text-singleline">
                                {{ contact.email }}
                            </span>
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogClient" persistent max-width="400px">
            <v-card>
                <v-card-title class="text-h5">Change Client</v-card-title>
                <v-card-text>
                    Are you sure you want to change the client?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="dialogClient = false">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="ableChangeClient" text color="error">
                        Change
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import API from '@/services/api'
import { storage } from '@/services/firebase'
import _ from 'lodash'
export default {
    name: 'ClientInformationQuote',
    props: {
        quote: Object,
        historyQuote: Boolean,
        settings: Array,
        quoteStatus: String,
    },
    data() {
        return {
            loadingError: false,
            errorMsg: null,
            loading: false,
            search: '',
            textClient: true,
            changeClient: false,
            dialogClient: false,
            companyId: JSON.parse(localStorage.getItem('company')),
            folderClientsPictures: 'clients_pictures',
            idSettingQuote: '',
            settingCommercial: null,
            userToFilter: null,
        }
    },
    inject: {
        replaceQuoteLocal: {
            from: 'replaceQuoteLocal',
            default() {
                return null
            },
        },
    },
    watch: {
        quote: async function(newQuote) {
            this.textClient = true
            this.changeClient = false
            if (newQuote) {
                await this.getClients()
            }
            this.updateSeeMenuAction(true)
        },
    },
    computed: {
        ...mapState([
            'saveBtn',
            'quotes',
            'contacts',
            'clients',
            'commercialTerms',
        ]),
    },
    methods: {
        ...mapActions([
            'quoteChangesAction',
            'addClientsAction',
            'addContactsAction',
            'updateSeeMenuAction',
            'addTermsAction',
        ]),
        ...mapMutations(['setErrorItems']),
        addContact: async function() {
            try {
                this.loading = true
                this.quote.contacts = this.quote.dataContacts.map(
                    contact => contact.id
                )
                this.quote.dataContacts = []
                this.quote.contacts.forEach(element => {
                    const dataContact = this.quote.client.contacts.find(
                        contact => element == contact.id
                    )
                    if (dataContact != undefined) {
                        this.quote.dataContacts.push(dataContact)
                    }
                })
                if (this.quote.dataContacts) {
                    await API.updateQuote(this.quote.id, {
                        clientId: this.quote.clientId,
                        contacts: this.quote.dataContacts.map(
                            contact => contact.id
                        ),
                        notificationId: 'LrubMkCSfpNwEoXemQKL',
                    })
                    this.replaceQuoteLocal(this.quote.id, this.quote)
                }
                this.quoteChangesAction(false)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        deleteSearch() {
            this.search = ''
        },
        openDialogClient() {
            this.dialogClient = true
        },
        ableChangeClient() {
            this.changeClient = true
            this.dialogClient = false
            this.textClient = false
        },
        async selectClient(event) {
            const dataClient = this.quote.clients.find(
                client => event == client.id
            )
            this.quote.dataContacts = []
            await this.addContact()
            this.quote.oldClient = _.cloneDeep(this.quote.client)
            this.quote.clientId = event
            this.quote.client = dataClient
            if (!this.quote.currency) {
                this.quote.currency = dataClient.currency
                const indexExchanges = this.settings.findIndex(
                    u => u.name === 'Exchange'
                )
                const settingExchange = this.settings[indexExchanges]
                const index = settingExchange.exchanges.findIndex(
                    u => u.currency === this.quote.currency
                )
                if (index >= 0) {
                    this.quote.exchange = parseInt(
                        settingExchange.exchanges[index].money
                    )
                } else {
                    this.quote.exchange = 0
                }
            }
            await this.getContactsByClient()
            const indexQuotes = this.settings.findIndex(
                u => u.name === 'Quotes'
            )
            this.idSettingQuote = this.settings[indexQuotes].id
            await this.getCommercialTerms()
            this.quote.contacts = []
            this.quote.dataContacts = []
            this.loading = false
            this.quoteChangesAction(false)
        },
        async getCommercialTerms() {
            try {
                const {
                    data: { commercialTerms },
                } = await API.getCommercialTerms(this.idSettingQuote)
                await this.addTermsAction(commercialTerms)
                if (this.quote.client.language == 'English') {
                    this.settingCommercial = this.commercialTerms.find(
                        x => x.id == 'english'
                    )
                } else {
                    this.settingCommercial = this.commercialTerms.find(
                        x => x.id == 'spanish'
                    )
                }
                if (this.settingCommercial.terms) {
                    this.quote.commercialTerms = this.settingCommercial.terms
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        getClients: async function() {
            try {
                this.loading = true
                this.loadingError = false
                this.errorMsg = null
                const {
                    data: { clients },
                } = await API.getLiteClients()
                const {
                    data: { roles },
                } = await API.getRoles(this.companyId)
                for (const client of clients) {
                    client.companyRoles = roles.roles
                    const clientQuotes = this.quotes.filter(
                        project => project.clientId == client.id
                    )
                    client.quotes = clientQuotes

                    if (this.quote.clientId == client.id) {
                        this.quote.client = client
                    }
                }
                if (this.quote.clientId) {
                    await this.getContactsByClient()
                    if (this.quote.contacts) {
                        this.quote.dataContacts = []
                        this.quote.contacts.forEach(element => {
                            const dataContact = this.quote.client.contacts.find(
                                contact => element == contact.id
                            )
                            if (dataContact) {
                                this.quote.dataContacts.push(dataContact)
                            }
                        })
                    }
                }
                this.quote.clients = clients
                await this.addClientsAction(clients)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async getContactsByClient() {
            try {
                this.loading = true
                const {
                    data: { contacts },
                } = await API.getContactsByClient({
                    clientId: this.quote.clientId,
                })
                for (const contact of contacts) {
                    if (contact.picture) {
                        const path = `${this.companyId}/${this.folderClientsPictures}`
                        const storageRef = storage().ref(path)
                        const profilePicRef = storageRef.child(contact.picture)
                        await profilePicRef
                            .getDownloadURL()
                            .then(url => {
                                contact.profilePicURL = url
                            })
                            .catch(() => {
                                contact.profilePicRef = ''
                            })
                    }
                }
                this.quote.client.contacts = contacts
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async setClientQuote() {
            try {
                if (this.quote.clientId) {
                    this.quote.client = this.clients.find(
                        client => this.quote.clientId == client.id
                    )
                }
                if (!this.quote.client.contacts) {
                    await this.getContactsByClient()
                }
                if (this.quote.contacts) {
                    this.quote.dataContacts = []
                    this.quote.contacts.forEach(element => {
                        const dataContact = this.quote.client.contacts.find(
                            contact => element == contact.id
                        )
                        if (dataContact) {
                            this.quote.dataContacts.push(dataContact)
                        }
                    })
                }
                this.quote.clients = this.clients
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
    async created() {
        try {
            if (this.quote) {
                await this.getClients()
            } else if (this.quote) {
                await this.setClientQuote()
            }

            this.quote.dataContacts = []
            this.quote.contacts.forEach(element => {
                const dataContact = this.quote.client.contacts.find(
                    contact => element == contact.id
                )
                if (dataContact != undefined) {
                    this.quote.dataContacts.push(dataContact)
                }
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.updateSeeMenuAction(true)
        }
    },
}
</script>
