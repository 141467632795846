<template>
    <v-dialog :retain-focus="false" v-model="show" persistent max-width="400px">
        <v-card :loading="loading">
            <v-card-title class="text-h5">
                <v-btn
                    class="mr-3"
                    depressed
                    color="primary"
                    icon
                    rounded
                    @click="closeDialog"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                {{ title }}</v-card-title
            >
            <v-card-text>
                <v-form ref="form" v-model="valid">
                    <v-row v-if="this.formUser == 'docCommercial'">
                        <v-col cols="12">
                            <v-text-field
                                v-model="doc.filename"
                                label="Name"
                                prepend-icon="mdi-form-textbox"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="doc.name"
                                label="Description"
                                prepend-icon="mdi-file-account-outline"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-file-input
                                v-model="files"
                                type="file"
                                label="File"
                                @change="onFilePicked"
                            ></v-file-input>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="quoteId"
                    text
                    color="primary"
                    :loading="loading"
                    :disabled="!valid"
                    @click="uploadDocQuote"
                >
                    Upload
                </v-btn>
                <v-btn
                    v-else
                    text
                    color="primary"
                    :loading="loading"
                    :disabled="!valid"
                    @click="upload"
                >
                    Upload
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import API from '@/services/api'
import moment from 'moment'
import { storage } from '@/services/firebase'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import { mapMutations } from 'vuex'

export default {
    name: 'DocumentForm',
    props: {
        title: {
            type: String,
            required: true,
            value: Boolean,
        },
        value: Boolean,
        tool: Object,
        formUser: {
            type: String,
            required: true,
        },
        userId: { type: String, default: null },
        quoteId: { type: String, default: null },
        quoteFiles: Array,
        clientId: {
            type: String,
            default: null,
        },
        doc: {
            type: Object,
            default() {
                return {
                    companyId: '',
                    name: '',
                    file: '',
                }
            },
        },
        originalDoc: Object,
    },
    data() {
        return {
            companyId: JSON.parse(localStorage.getItem('company')),
            folderClients: 'clients_documents',
            folderUsers: 'users_documents',
            folderQuotes: 'quotes_documents',
            folderLead: 'leads_documents',
            folderDocCommercial: 'commercial_documents',
            folderDocTech: 'technical_documents',
            loading: false,
            error: false,
            errorMsg: null,
            valid: false,
            rules: {
                required: v => !!v || 'Required',
                value: v => !v || v.size > 0 || 'Required',
            },
            deleteDialog: false,
            deleteError: false,
            documentURL: '',
            files: [],
            nameFile: '',
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onFilePicked: function() {
            this.nameFile = uuidv4()
            this.doc.file = this.nameFile
            this.doc.companyId = this.companyId
            this.doc.userId = this.userId
        },
        closeDialog: function() {
            this.$emit('closeDialog')
            this.$refs.form.reset()
            this.error = false
            this.errorMsg = null
        },
        uploadDocQuote: function() {
            if (this.title == 'Documents Quote') {
                this.uploadDocumentQuote()
            } else if (this.title == 'Attach Document') {
                this.uploadAttachQuote()
            } else if (this.title == 'Documents Leads') {
                this.uploadDocumentLead()
            } else if (this.title == 'Attach Leads') {
                this.uploadAttachLead()
            }
        },
        async uploadFollowUpDocuments() {},
        uploadDocumentClient: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                this.doc.clientId = this.clientId
                const extension = this.files.name.split('.')[1]
                const path = `${this.companyId}/${this.folderClients}/${this.clientId}`
                const storageRef = storage().ref(path)
                const clientDocRef = storageRef.child(
                    this.nameFile + '.' + extension
                )
                var metadata = {
                    customMetadata: {
                        filename: this.files.name,
                    },
                }
                await clientDocRef.put(this.files, metadata)
                if (!this.doc.name) {
                    this.doc.name = this.files.name
                }
                const {
                    data: { document },
                } = await API.createDocumentClient(this.doc)

                clientDocRef
                    .getDownloadURL()
                    .then(url => {
                        document.url = url
                    })
                    .catch(error => {
                        document.url = ''
                        console.log(error)
                    })
                document.createdOn = moment
                    .unix(document.createdOn._seconds)
                    .format('MMM/DD/YYYY HH:mm')
                document.filename = this.files.name
                this.$emit('addDocument', document)

                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        makeid() {
            var length = 6
            var result = ''
            var characters =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            var charactersLength = characters.length
            for (var i = 0; i < length; i++) {
                result += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                )
            }
            return result.toUpperCase()
        },
        upload: function() {
            if (this.formUser == 'docCommercial') {
                this.uploadDocCommercial()
            } else if (this.formUser == 'docTechnical') {
                this.uploadDocTechnical()
            } else if (this.formUser == 'true') {
                this.uploadDocumentUser()
            } else {
                this.uploadDocumentClient()
            }
        },
        uploadDocumentQuote: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const extension = this.files.name.split('.')[1]
                const path = `${this.companyId}/${this.folderQuotes}/${this.quoteId}`
                const storageRef = storage().ref(path)
                const fileId = this.makeid()
                const quoteDocRef = storageRef.child(
                    fileId + '-' + this.files.name
                )
                var metadata = {
                    customMetadata: {
                        filename: this.files.name,
                    },
                }
                await quoteDocRef.put(this.files, metadata)
                const newQuote = {}
                this.doc.file = this.doc.file + '.' + extension
                if (this.doc.name) {
                    const file = {
                        name: this.doc.name,
                        file: fileId + '-' + this.files.name,
                        versionQuote: false,
                        attach: false,
                    }
                    this.quoteFiles.push(file)
                    newQuote.files = this.quoteFiles
                    await API.updateQuote(this.quoteId, {
                        notificationId: 'LrubMkCSfpNwEoXemQKL',
                        ...newQuote,
                    })
                    this.$emit('addFile', file)
                } else {
                    const file = {
                        name: this.files.name,
                        file: fileId + '-' + this.files.name,
                        versionQuote: false,
                        attach: false,
                    }
                    this.quoteFiles.push(file)
                    newQuote.files = this.quoteFiles
                    await API.updateQuote(this.quoteId, {
                        notificationId: 'LrubMkCSfpNwEoXemQKL',
                        ...newQuote,
                    })
                    this.$emit('addFile', file)
                }

                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        uploadAttachQuote: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const extension = this.files.name.split('.')[1]
                const path = `${this.companyId}/${this.folderQuotes}/${this.quoteId}`
                const storageRef = storage().ref(path)
                const fileId = this.makeid()
                const quoteDocRef = storageRef.child(
                    fileId + '-' + this.files.name
                )
                var metadata = {
                    customMetadata: {
                        filename: this.files.name,
                    },
                }
                await quoteDocRef.put(this.files, metadata)
                const newQuote = {}
                this.doc.file = this.doc.file + '.' + extension
                if (this.doc.name) {
                    const file = {
                        name: this.doc.name,
                        file: fileId + '-' + this.files.name,
                        versionQuote: false,
                        attach: true,
                    }
                    this.quoteFiles.push(file)
                    newQuote.files = this.quoteFiles
                    await API.updateQuote(this.quoteId, {
                        notificationId: 'LrubMkCSfpNwEoXemQKL',
                        ...newQuote,
                    })
                    this.$emit('addFile', file)
                } else {
                    const file = {
                        name: this.files.name,
                        file: fileId + '-' + this.files.name,
                        versionQuote: false,
                        attach: true,
                    }
                    this.quoteFiles.push(file)
                    newQuote.files = this.quoteFiles
                    await API.updateQuote(this.quoteId, {
                        notificationId: 'LrubMkCSfpNwEoXemQKL',
                        ...newQuote,
                    })
                    this.$emit('addFile', file)
                }

                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        uploadDocumentLead: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const extension = this.files.name.split('.')[1]
                const path = `${this.companyId}/${this.folderLead}/${this.quoteId}`
                const storageRef = storage().ref(path)
                const fileId = this.makeid()
                const quoteDocRef = storageRef.child(
                    fileId + '-' + this.files.name
                )
                var metadata = {
                    customMetadata: {
                        filename: this.files.name,
                    },
                }
                await quoteDocRef.put(this.files, metadata)
                const newQuote = {}
                this.doc.file = this.doc.file + '.' + extension
                if (this.doc.name) {
                    const file = {
                        name: this.doc.name,
                        file: fileId + '-' + this.files.name,
                        versionQuote: false,
                        attach: false,
                    }
                    this.quoteFiles.push(file)
                    newQuote.files = this.quoteFiles
                    await API.updateLead({ id: this.quoteId, ...newQuote })
                    this.$emit('addFile', file)
                } else {
                    const file = {
                        name: this.files.name,
                        file: fileId + '-' + this.files.name,
                        versionQuote: false,
                        attach: false,
                    }
                    this.quoteFiles.push(file)
                    newQuote.files = this.quoteFiles
                    await API.updateLead({ id: this.quoteId, ...newQuote })
                    this.$emit('addFile', file)
                }

                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        uploadAttachLead: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const extension = this.files.name.split('.')[1]
                const path = `${this.companyId}/${this.folderLead}/${this.quoteId}`
                const storageRef = storage().ref(path)
                const fileId = this.makeid()
                const quoteDocRef = storageRef.child(
                    fileId + '-' + this.files.name
                )
                var metadata = {
                    customMetadata: {
                        filename: this.files.name,
                    },
                }
                await quoteDocRef.put(this.files, metadata)
                const newQuote = {}
                this.doc.file = this.doc.file + '.' + extension
                if (this.doc.name) {
                    const file = {
                        name: this.doc.name,
                        file: fileId + '-' + this.files.name,
                        versionQuote: false,
                        attach: true,
                    }
                    this.quoteFiles.push(file)
                    newQuote.files = this.quoteFiles
                    await API.updateLead({ id: this.quoteId, ...newQuote })
                    this.$emit('addFile', file)
                } else {
                    const file = {
                        name: this.files.name,
                        file: fileId + '-' + this.files.name,
                        versionQuote: false,
                        attach: true,
                    }
                    this.quoteFiles.push(file)
                    newQuote.files = this.quoteFiles
                    await API.updateLead({ id: this.quoteId, ...newQuote })
                    this.$emit('addFile', file)
                }
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        uploadDocumentUser: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const extension = this.files.name.split('.')[1]
                const path = `${this.companyId}/${this.folderUsers}/${this.userId}`
                const storageRef = storage().ref(path)
                const clientDocRef = storageRef.child(this.files.name)
                var metadata = {
                    customMetadata: {
                        filename: this.files.name,
                    },
                }
                await clientDocRef.put(this.files, metadata)
                this.doc.file = this.doc.file + '.' + extension
                if (this.doc.name === '') {
                    this.doc.name = this.files.name
                }
                const {
                    data: { document },
                } = await API.createDocumentUser(this.doc)

                clientDocRef
                    .getDownloadURL()
                    .then(url => {
                        document.url = url
                    })
                    .catch(() => {
                        document.url = ''
                    })

                document.createdOn = moment
                    .unix(document.createdOn._seconds)
                    .format('MMM/DD/YYYY HH:mm')
                document.filename = this.files.name

                this.$emit('addDocument', document)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        uploadDocCommercial: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                let extension = ''
                let docRef = undefined
                if (this.files?.name) {
                    extension = this.files.name.split('.')[1]
                    const path = `${this.companyId}/${this.folderDocCommercial}`
                    const storageRef = storage().ref(path)
                    docRef = storageRef.child(this.files.name)
                    if (this.title == 'New Document') {
                        const metadata = {
                            customMetadata: {
                                filename: this.files.name,
                            },
                        }
                        await docRef.put(this.files, metadata)
                    } else if (this.title == 'Edit Document') {
                        const oldDocRef = storageRef.child(
                            this.originalDoc.file
                        )
                        await oldDocRef.delete()
                        const metadata = {
                            customMetadata: {
                                filename: this.files.name,
                            },
                        }
                        await docRef.put(this.files, metadata)
                    }
                }
                const newId = this.makeid()
                this.doc.file =
                    this.doc.file + (extension ? '.' + extension : '')
                const file = {
                    id: newId,
                    name: this.doc.name,
                    file: this.files.name,
                    filename: this.doc.filename
                        ? this.doc.filename + (extension ? '.' + extension : '')
                        : this.files.name,
                }
                if (!this.doc.name) {
                    file.name = this.files.name
                }
                if (this.originalDoc.id) {
                    const index = this.tool.commercialDocuments.findIndex(
                        item => item.id == this.originalDoc.id
                    )
                    if (index > -1) {
                        if (this.files?.name) {
                            file.docURL = await docRef.getDownloadURL()
                        }
                        this.tool.commercialDocuments.splice(
                            index,
                            1,
                            this.files?.name
                                ? file
                                : {
                                      ...this.originalDoc,
                                      name: this.doc.name,
                                      filename: this.doc.filename,
                                  }
                        )
                    }
                }
                const commercialDocuments = _.cloneDeep(
                    this.tool.commercialDocuments
                )
                commercialDocuments.forEach(i => {
                    delete i.docURL
                })
                if (!this.originalDoc.id) {
                    commercialDocuments.push({ ...file })
                    file.docURL = await docRef.getDownloadURL()
                    this.tool.commercialDocuments.push(file)
                }
                await API.updateTools(this.tool.id, { commercialDocuments })
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        uploadDocTechnical: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                // const extension = this.files.name.split(".")[1];
                const path = `${this.companyId}/${this.folderDocTech}`
                const storageRef = storage().ref(path)
                const profilePicRef = storageRef.child(this.files.name)
                var metadata = {
                    customMetadata: {
                        filename: this.files.name,
                    },
                }
                await profilePicRef.put(this.files, metadata)
                const newId = this.makeid()
                this.doc.file = this.files.name
                const file = {
                    id: newId,
                    name: this.doc.name,
                    file: this.files.name,
                    filename: this.files.name,
                }
                if (!this.doc.name) {
                    file.name = this.files.name
                }
                const technicalDocuments = _.cloneDeep(
                    this.tool.technicalDocuments
                )
                technicalDocuments.forEach(i => {
                    delete i.docURL
                })
                technicalDocuments.push(file)
                await API.updateTools(this.tool.id, { technicalDocuments })
                file.docURL = await profilePicRef.getDownloadURL()
                this.tool.technicalDocuments.push(file)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
