<template>
    <div>
        <v-row
            v-if="loading"
            class="fill-height ma-0"
            align="center"
            justify="center"
        >
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </v-row>

        <v-row v-if="!loading" class="align-center mt-1 font-weight-bold">
            <v-col>
                <span>Header Image</span>
            </v-col>
        </v-row>
        <v-row v-if="quote" class="" no-gutters>
            <v-col>
                <div v-if="docImages.length > 0" class="d-flex content">
                    <v-card
                        :color="hover(i)"
                        @click="selectImage(i)"
                        flat
                        class="mr-3 mb-3 pa-1"
                        :key="i"
                        v-for="(image, i) in this.docImages"
                    >
                        <HeaderImage :image="image" />
                    </v-card>
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import API from '@/services/api'
import { storage } from '@/services/firebase'
import _ from 'lodash'
export default {
    name: 'HeaderImages',
    props: {
        quote: Object,
        id: String,
    },
    components: {
        HeaderImage: () => import('@/components/Quotes/HeaderImage.vue'),
    },
    data() {
        return {
            loading: false,
            folderPdfImage: 'pdf_images',
            companyId: JSON.parse(localStorage.getItem('company')),
            loadingError: false,
            errorMsg: null,
            docImages: [],
            model: null,
        }
    },
    computed: {
        ...mapState(['saveBtn', 'pdfImages']),
    },
    watch: {
        quote: function() {
            this.model = null
            this.setImages()
        },
        id: function(newId) {
            if (!this.pdfImages && newId) {
                this.getPdfImages()
            } else {
                this.model = null
                this.setImages()
            }
        },
    },
    methods: {
        ...mapActions(['addImagesAction', 'quoteChangesAction']),
        ...mapMutations(['setErrorItems']),
        hover(i) {
            if (i == this.model) {
                return 'primary'
            } else return 'normal'
        },
        async setImages() {
            this.docImages = this.pdfImages.images
            if (this.quote.image) {
                const index = this.docImages.findIndex(
                    i => i.id == this.quote.image.id
                )
                this.model = index
            } else {
                const defaultImageIndex = this.pdfImages.images.findIndex(
                    image => image.defaultImage
                )
                if (defaultImageIndex >= 0) {
                    await this.selectImage(defaultImageIndex)
                    //this.quote.image = this.pdfImages.images[defaultImageIndex];
                }
            }
        },
        async selectImage(event) {
            await this.quoteChangesAction(false)
            this.quote.image = _.cloneDeep(this.docImages[event])
            const path = `${this.companyId}/${this.folderPdfImage}`
            const storageRef = storage().ref(path)
            const profilePicRef = storageRef.child(this.quote.image.file)
            await profilePicRef
                .getDownloadURL()
                .then(URL => {
                    this.quote.image.imageURL = URL
                })
                .catch(error => {
                    this.quote.image.imageURL = ''
                    error && true
                })
            this.model = event
        },
        getPdfImages: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const {
                    data: { pdfImages },
                } = await API.getPdfImages(this.id)
                await this.addImagesAction(pdfImages[0])
                await this.setImages()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
    async created() {
        if (!this.pdfImages && this.id) {
            await this.getPdfImages()
        } else {
            await this.setImages()
        }
    },
}
</script>

<style scoped>
.text-singleline {
    line-height: 0.75rem;
}
.content {
    overflow-x: auto;
}
.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 0px;
}
</style>
