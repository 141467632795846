<template>
    <v-row no-gutters>
        <v-col cols="4" xl="1" lg="1" md="2" sm="1" class="d-flex align-center">
            <span>Tags</span>
        </v-col>
        <v-col class="d-flex align-center flex-wrap">
            <v-card
                v-for="(tag, index) in selectedTags"
                :key="index"
                flat
                class="mr-1 ml-1"
            >
                <v-chip>
                    <span>
                        {{ tag }}
                    </span>
                    <v-btn icon small class="ml-2" @click="deleteTag(index)">
                        <v-icon size="20">mdi-close-circle</v-icon>
                    </v-btn>
                </v-chip>
            </v-card>
            <v-btn
                @click="openDialogTags"
                class="my-2 ml-2"
                depressed
                fab
                x-small
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-col>
        <v-dialog
            :retain-focus="false"
            v-model="addDialog"
            persistent
            max-width="400px"
        >
            <v-card :loading="loading">
                <v-card-title class="text-h5">Add Tags</v-card-title>
                <v-card-text>
                    <div class="tag-input align-center">
                        <v-form>
                            <v-autocomplete
                                @change="deleteSearch"
                                :search-input.sync="search"
                                label="Tags"
                                v-model="selectedTags"
                                :items="tags"
                                chips
                                multiple
                            >
                            </v-autocomplete>
                        </v-form>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeTagsDialog">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="updateTags"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'
import _ from 'lodash'
import moment from 'moment'

export default {
    name: 'TaskTags',
    props: {
        task: Object,
        createTask: {
            type: Boolean,
            defautl: () => false,
        },
    },
    data: () => ({
        loading: false,
        selectedTags: [],
        selectedTask: {},
        originalTask: {},
        createForm: false,
        openTaskForm: true,
        addDialog: false,
        settings: undefined,
        tags: [],
        search: '',
    }),
    async mounted() {
        try {
            this.loading = true
            this.selectedTags = this.task.tags ? this.task.tags : []
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        openEditDialog() {
            this.selectedTask = _.cloneDeep(this.task)
            this.originalTask = _.cloneDeep(this.task)
            this.createForm = false
            this.openTaskForm = true
        },
        closeTaskFormDialog: function() {
            this.createForm = false
            this.openTaskForm = false
            this.selectedTask = {}
            this.originalTask = {}
        },
        deleteSearch() {
            this.search = ''
        },
        closeTagsDialog() {
            this.addDialog = false
        },
        async openDialogTags() {
            try {
                this.loading = true
                const {
                    data: { settings },
                } = await API.getSettings()
                this.settings = settings
                let companyInfo = this.settings.find(s => s.name === 'Company')
                this.tags = companyInfo.tags
                this.addDialog = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async deleteTag(index) {
            try {
                this.loading = true
                this.selectedTags.splice(index, 1)
                await this.updateTags()
                this.task.tags.splice(index, 1)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async updateTags() {
            try {
                this.loading = true
                if (!this.createTask) {
                    await API.updateTask(this.task.id, {
                        tags: this.selectedTags,
                        assignedTo: this.task.assignedTo,
                        notificationId: 'rCJFinVt7UiC6uTysz2i',
                    })
                    this.$emit('replaceTask', {
                        id: this.task.id,
                        tags: this.selectedTags,
                        startDate: moment.unix(
                            this.task.startDate._seconds
                                ? this.task.startDate._seconds
                                : this.task.startDate.seconds
                        ),
                        expectedDate: moment.unix(
                            this.task.expectedDate._seconds
                                ? this.task.expectedDate._seconds
                                : this.task.expectedDate.seconds
                        ),
                    })
                } else {
                    this.$emit('replaceTask', {
                        id: this.task.id,
                        tags: this.selectedTags,
                    })
                }
                this.closeTagsDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style></style>
