<template>
    <div>
        <v-data-table
            hide-default-footer
            :headers="headers"
            color="grey lighten-3"
            :items="taskChilds"
            :loading="loading"
            class="elevation-1"
            hide-default-header
        >
            <template v-slot:top>
                <v-row
                    class="d-flex justify-end mx-0 mb-0"
                    style="background: #dfe1e6"
                >
                    <v-col>
                        <h3 class="mt-3 ml-1">
                            Childs ({{ calculatePercentage() }})
                        </h3>
                    </v-col>
                    <v-spacer />
                    <v-col class="mt-n2 text-right">
                        <v-btn
                            depressed
                            color="primary"
                            rounded
                            class="mt-3"
                            @click="createChild"
                        >
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            New
                        </v-btn>
                    </v-col>
                </v-row>
            </template>

            <!-- ITEMS -->
            <template v-slot:[`item.description`]="{ item }">
                <div class="d-flex">
                    <v-simple-checkbox
                        color="primary"
                        v-model="item.done"
                        inset
                        @click="completeTaskChild(item)"
                        :disabled="children && children.length >= 0"
                    ></v-simple-checkbox>
                    <span class="mt-0 text-body-1">{{ item.description }}</span>
                </div>
            </template>
            <template v-slot:[`item.percentage`]="{ item }">
                <div>
                    <span class="mt-5 text-body-1 text-center"
                        >{{ item.percentage }}%</span
                    >
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn icon small @click="updateChild(item)">
                        <v-icon>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                    <v-btn icon small @click="deleteChild(item)">
                        <v-icon>
                            mdi-delete
                        </v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <!-- Task Child Form -->
        <v-dialog
            :retain-focus="false"
            v-model="openTaskChildForm"
            persistent
            max-width="440px"
        >
            <TaskChildForm
                v-if="openTaskChildForm"
                :taskId="task.id"
                :assignedTo="task.assignedTo"
                :createForm="createForm"
                :child="selectedChild"
                :originalChild="originalChild"
                :children="children"
                @addChild="addChild"
                @replaceChild="replaceChild"
                @closeDialog="closeChildFormDialog"
            />
        </v-dialog>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import TaskChildForm from '@/components/Tasks/TaskChildForm.vue'
import _ from 'lodash'

export default {
    name: 'TaskChild',
    props: {
        task: Object,
        children: {
            type: Array,
            default: () => undefined,
        },
    },
    components: {
        TaskChildForm,
    },
    data: () => ({
        loading: false,
        taskChilds: [],
        headers: [
            {
                text: 'name',
                value: 'description',
                align: 'left',
            },
            {
                text: 'percentage',
                value: 'percentage',
                align: 'center',
            },
            {
                text: 'actions',
                value: 'actions',
                align: 'center',
            },
        ],
        selectedChild: {},
        originalChild: {},
        createForm: false,
        openTaskChildForm: false,
    }),
    async mounted() {
        try {
            this.loading = true
            if (this.task.id) {
                this.taskChilds = await API.getTaskChilds(
                    this.task.id,
                    this.task.assignedTo
                )
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        calculatePercentage() {
            let total = 0
            total += this.taskChilds.reduce(
                (accumulator, child) =>
                    accumulator + (child.done ? child.percentage : 0),
                0
            )
            return total
        },
        async deleteChild(item) {
            try {
                this.loading = true
                if (this.children == undefined) {
                    await API.deleteTaskChild(
                        this.task.id,
                        item.id,
                        this.task.assignedTo
                    )
                } else {
                    const index = this.children.findIndex(c => c.id === item.id)
                    if (index >= 0) {
                        this.children.splice(index, 1)
                    }
                }
                const index = this.taskChilds.findIndex(c => c.id === item.id)
                if (index >= 0) {
                    this.taskChilds.splice(index, 1)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async completeTaskChild(child) {
            try {
                this.loading = true
                let modifiedChild = undefined
                if (this.children && this.children.length > 0) {
                    modifiedChild = {
                        id: child.id,
                        done: child.done,
                    }
                } else {
                    modifiedChild = await API.updateTaskChild(child.id, {
                        taskId: this.task.id,
                        done: child.done,
                        assignedTo: this.task.assignedTo,
                        notificationId: 'rCJFinVt7UiC6uTysz2i',
                    })
                }
                this.replaceChild(modifiedChild)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeChildFormDialog() {
            this.selectedChild = {}
            this.openTaskChildForm = false
        },
        updateChild(child) {
            this.selectedChild = _.cloneDeep(child)
            this.originalChild = _.cloneDeep(child)
            this.createForm = false
            this.openTaskChildForm = true
        },
        async replaceChild(child) {
            if (this.children !== undefined) {
                const index = this.children.findIndex(c => c.id === child.id)
                if (index >= 0) {
                    this.children.splice(index, 1, {
                        ...this.children[index],
                        ...child,
                    })
                }
            }
            const index = this.taskChilds.findIndex(c => c.id === child.id)
            if (index >= 0) {
                this.taskChilds.splice(index, 1, {
                    ...this.taskChilds[index],
                    ...child,
                })
            }
        },
        createChild() {
            this.createForm = true
            this.openTaskChildForm = true
        },
        async addChild(child) {
            this.taskChilds.push(child)
        },
    },
}
</script>

<style></style>
