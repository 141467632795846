<template>
    <v-sheet class="fill-height" color="transparent">
        <v-lazy
            :v-model="user.tasks"
            :options="{
                threshold: 0.5,
            }"
            class="fill-height"
        >
            <v-card
                style="background-color: #fafafa; border-radius: 10px"
                flat
                class="ma-0 pa-0"
            >
                <v-row class="d-flex ma-0 pa-0">
                    <v-avatar class="ml-3 mt-3 pa-0" size="28">
                        <v-img
                            v-if="profilePicUrl"
                            :src="profilePicUrl"
                            :alt="user.name"
                        />
                        <v-icon v-else x-large color="#707070"
                            >mdi-account-circle</v-icon
                        >
                    </v-avatar>
                    <div class="ma-0 pa-2 pb-1">
                        <p class="ma-0 pa-0 font-weight-bold">
                            {{ user.name }}
                        </p>
                        <p class="ma-0 pa-0 mt-n1">
                            {{ user.role }}
                        </p>
                    </div>
                </v-row>
                <v-row class="flex-nowrap ma-0 pa-0">
                    <draggable
                        class="list-group overflow-y-auto px-2 py-0 ma-0"
                        :style="`height:${height - 68}px; width: 100%`"
                        v-model="user.tasks"
                        group="tasks"
                        @change="draggableLog"
                    >
                        <div
                            class="list-group-item py-0 mx-0 my-3 px-1"
                            v-for="(task, index) in user.tasks"
                            :key="index"
                        >
                            <v-sheet
                                v-if="task"
                                min-height="100"
                                class="fill-height"
                                color="transparent"
                            >
                                <v-lazy
                                    :v-model="task"
                                    :options="{
                                        threshold: 0.5,
                                    }"
                                    class="fill-height"
                                >
                                    <TaskCard
                                        :task="task"
                                        :users="users"
                                        @openTaskDetails="openTaskDetails"
                                        @enableLoadAnimation="
                                            enableLoadAnimation
                                        "
                                    />
                                </v-lazy>
                            </v-sheet>
                        </div>
                    </draggable>
                </v-row>
            </v-card>
        </v-lazy>
        <v-dialog
            :retain-focus="false"
            v-model="viewTaskDetails"
            persistent
            max-width="640px"
        >
            <TaskDetails
                v-if="viewTaskDetails"
                :task="selectedTask"
                :users="users"
                @closeDialog="closeTaskDetails"
                @replaceTask="replaceTask"
                @deleteTask="deleteTask"
            />
        </v-dialog>
    </v-sheet>
</template>

<script>
import API from '@/services/api'
import draggable from 'vuedraggable'
import TaskCard from '@/components/Tasks/TaskCard.vue'
import TaskDetails from '@/components/Tasks/TaskDetails.vue'
import { loadImage } from '@/helpers/imageHandler'
import moment from 'moment'
import { mapMutations, mapActions, mapGetters, mapState } from 'vuex'
import _ from 'lodash'

export default {
    name: 'UserDraggable',
    components: {
        draggable,
        TaskCard,
        TaskDetails,
    },
    props: {
        user: Object,
        users: Array,
        height: Number,
    },
    data() {
        return {
            companyId: JSON.parse(localStorage.getItem('company')),
            folderUsersPictures: 'users_pictures',
            profilePicUrl: null,
            selectedTask: {},
            viewTaskDetails: false,
            resourceId: undefined,
        }
    },
    provide() {
        return {
            closeTaskDetails: this.closeTaskDetails,
        }
    },
    inject: {
        enableLoadAnimation: {
            from: 'enableLoadAnimation',
            default() {
                return null
            },
        },
    },
    computed: {
        ...mapState(['notificationResource']),
    },
    async mounted() {
        try {
            if (this.user.picture) {
                try {
                    const path = `${this.companyId}/${this.folderUsersPictures}`
                    this.profilePicUrl = await loadImage(
                        path,
                        this.user.picture
                    )
                } catch (error) {
                    console.log(error.message)
                }
            }
            this.resourceId = this.$route.query.resourceId
            this.openResource()
        } catch (error) {
            this.setItemsError(error.message)
        }
    },
    watch: {
        notificationResource: function(resource) {
            if (resource) {
                this.resourceId = resource
                this.openResource()
                this.setNotificationResource(undefined)
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        ...mapActions(['setNotificationResource']),
        ...mapGetters(['getNotificationResource']),
        deleteTask(taskId) {
            //this.user.tasks = this.user.tasks.filter(task => task.id != taskId)
            this.$emit('deleteTask', this.user.id, taskId)
        },
        replaceTask(task) {
            this.selectedTask = { ...this.selectedTask, ...task }
            let date = new Date(this.selectedTask.startDate)
            this.selectedTask.startDate = {
                seconds: date.getTime() / 1000,
            }
            date = new Date(this.selectedTask.expectedDate)
            this.selectedTask.expectedDate = {
                seconds: date.getTime() / 1000,
            }
            const userIndex = this.users.findIndex(
                user => user.id === this.selectedTask.assignedTo
            )
            if (userIndex >= 0) {
                const taskIndex = this.user.tasks.findIndex(
                    task => task.id === this.selectedTask.id
                )
                if (taskIndex >= 0) {
                    this.user.tasks.splice(taskIndex, 1, {
                        ...this.user.tasks[taskIndex],
                        ...this.selectedTask,
                    })
                    this.users.splice(userIndex, 1, this.user)
                }
            }
        },
        draggableLog: async function(evt) {
            try {
                if (evt.added) {
                    let task = _.cloneDeep(evt.added.element)
                    delete task.id
                    delete task.createdOn
                    delete task.timestamp
                    delete task.updatedOn
                    delete task.archive
                    delete task.createdBy
                    delete task.status
                    delete task.seconds
                    Object.keys(task).forEach(key => {
                        if (!task[key]) delete task[key]
                    })
                    task.startDate = moment
                        .unix(task.startDate._seconds)
                        .format('YYYY-MM-DD')
                    task.expectedDate = moment
                        .unix(task.expectedDate._seconds)
                        .format('YYYY-MM-DD')
                    task.assignedTo = this.user.id
                    const index = evt.added.newIndex
                    let beforeIndex = 0
                    let afterIndex = 0
                    if (index > 0) {
                        beforeIndex = this.user.tasks[index - 1].index
                    }

                    if (index < this.user.tasks.length - 1) {
                        afterIndex = this.user.tasks[index + 1].index
                    } else if (index == this.user.tasks.length - 1) {
                        afterIndex = beforeIndex + 1
                    }
                    task.index = (beforeIndex + afterIndex) / 2
                    const newTask = await API.createTask({
                        notificationId: 'spnIVZA9IB6tsWj8OD46',
                        ...task,
                    })
                    const taskIndex = this.user.tasks.findIndex(
                        t => t.id == evt.added.element.id
                    )
                    this.user.tasks.splice(taskIndex, 1, {
                        ...this.user.tasks[taskIndex],
                        assignedTo: this.user.id,
                        id: newTask.id,
                        index: task.index,
                    })
                } else if (evt.removed) {
                    const task = evt.removed.element
                    await API.deleteTask({
                        id: task.id,
                        assignedTo: task.assignedTo,
                    })
                } else if (evt.moved) {
                    const index = evt.moved.newIndex
                    let task = evt.moved.element
                    let beforeIndex = 0
                    let afterIndex = 0
                    if (index > 0) {
                        beforeIndex = this.user.tasks[index - 1].index
                    }

                    if (index < this.user.tasks.length - 1) {
                        afterIndex = this.user.tasks[index + 1].index
                    } else if (index == this.user.tasks.length - 1) {
                        afterIndex = beforeIndex + 1
                    }

                    const newIndex = (beforeIndex + afterIndex) / 2
                    await API.updateTask(task.id, {
                        index: newIndex,
                        assignedTo: task.assignedTo,
                        notificationId: 'rCJFinVt7UiC6uTysz2i',
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        openTaskDetails(task) {
            this.selectedTask = _.cloneDeep(task)
            this.viewTaskDetails = true
        },
        closeTaskDetails() {
            this.selectedTask = {}
            this.viewTaskDetails = false
        },
        openResource() {
            if (this.resourceId) {
                const resource = this.user.tasks.find(
                    t => t.id == this.resourceId
                )
                if (resource) {
                    this.resourceId = undefined
                    this.openTaskDetails(resource)
                }
            }
        },
    },
}
</script>
<style scoped>
.borderRounded {
    border-radius: 15px;
}

.underlineTag {
    text-decoration: underline #3277d1;
    font-weight: bold;
    font-size: 13px;
    color: #3277d1;
}
</style>
