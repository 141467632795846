<template>
  <v-dialog :retain-focus="false" v-model="show" persistent max-width="300px">
    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" v-if="createForm" class="d-flex">
          <v-text-field
            @input="details"
            label="Details"
            :rules="[rules.required]"
            required
          >
          </v-text-field>
          <v-text-field
            @input="percentage"
            type="number"
            max="100"
            class="mx-3"
            :rules="[rules.number]"
            suffix="%"
            required
          >
          </v-text-field>
        </v-form>
        <v-form ref="deleteForm" v-if="!createForm" disabled class="d-flex">
          <v-text-field v-model="comToDelete.details" label="Details" required>
          </v-text-field>
          <v-text-field
            v-model="comToDelete.percentage"
            type="number"
            class="mx-3"
            prefix="%"
            required
          >
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="mx-3"
          v-if="createForm"
          text
          color="secondary"
          @click="closeDialog"
        >
          Close
        </v-btn>
        <v-btn
          v-if="!createForm"
          text
          class="mx-3"
          color="secondary"
          @click="closeDialogDelete"
        >
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!createForm"
          text
          class="mx-3"
          color="error"
          @click="deleteCommission(commission)"
        >
          Delete
        </v-btn>
        <v-spacer v-if="createForm"></v-spacer>
        <v-btn
          :disabled="!valid"
          class="mx-3"
          v-if="createForm"
          @click="addCommission(comToDelete)"
          text
        >
          ADD
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CommissionForm",
  props: {
    createForm: Boolean,
    commission: Object,
    title: String,
    value: Boolean
  },
  data() {
    return {
      valid: false,
      mount: "null",
      detail: "null",
      comToDelete: {},
      rules: {
        required: v => !!v || "Required",
        number: v => (v <= 100 && v > 0) || "Error"
      }
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  created() {
    this.comToDelete = this.commission;
  },
  watch: {
    commission: function(newC) {
      this.comToDelete = newC;
    }
  },
  methods: {
    closeDialog: function() {
      this.$refs.form.reset();
      this.$emit("closeDialog");
    },
    closeDialogDelete: function() {
      this.$emit("closeDialog");
    },
    deleteCommission: function(commission) {
      this.$emit("deleteCommission", commission.details);
    },
    addCommission: function() {
      const commission = { details: this.detail, percentage: this.mount };
      this.$refs.form.reset();
      this.$emit("addCommission", commission);
      this.detail = "";
      this.mount = "";
    },
    details(event) {
      this.detail = event;
    },
    percentage(event) {
      this.mount = event;
    }
  }
};
</script>
