<template>
    <v-container
        v-resize="onResize"
        fluid
        :style="{
            height: `${height}px !important`,
            'background-color': 'gray lighten-5',
        }"
        :loading="loading"
    >
        <input type="text" id="second" style="position: absolute; top: -1" />
        <v-progress-linear v-if="loading" color="primary" />
        <v-row>
            <v-container
                class="posts ma-0 pa-0"
                :style="{ height: `${postsHeight}px !important` }"
                fluid
                id="chat-container"
            >
                <FollowUpPost
                    v-for="(post, index) of posts"
                    :key="index"
                    :post="post"
                    :quoteId="id"
                    :users="users"
                    :showDate="
                        index == 0 ||
                            new Date(post.createdOn._seconds * 1000).getDay() !=
                                new Date(
                                    posts[index - 1].createdOn._seconds * 1000
                                ).getDay()
                    "
                    @viewError="viewError"
                    @openEvent="openEvent"
                />
            </v-container>
        </v-row>
        <v-row>
            <v-container fluid class="py-0 px-0">
                <v-row>
                    <v-col
                        cols="12"
                        class="d-flex justify-start"
                        style="height: 150px !important"
                    >
                        <quill-editor
                            id="editor"
                            v-model="message"
                            ref="quillEditor"
                            :options="editorOption"
                            @change="messageHandler"
                        >
                        </quill-editor>
                        <v-menu
                            v-if="menu2 && filteredUsers.length > 0"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-width="200"
                            offset-y
                            :position-x="positionX"
                            :position-y="positionY"
                            max-height="200"
                        >
                            <v-list>
                                <v-list-item
                                    v-for="user in filteredUsers"
                                    :key="user.id"
                                    @click="addMention(user)"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title>{{
                                            user.name
                                        }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                        md="6"
                        xl="6"
                        lg="6"
                        class="d-flex justify-start pt-0 pb-0 mt-2"
                    >
                        <div class="mx-2">
                            <h4>Probability</h4>
                            <p>{{ probability }}</p>
                        </div>
                        <div class="d-flex">
                            <div class="mx-2">
                                <h4>Expected date</h4>
                                <p>{{ expectedDate }}</p>
                            </div>
                            <v-btn
                                small
                                icon
                                @click="openProjectionForm"
                                class="mx-2 mt-1 justify-center"
                                id="probability"
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </div>
                        <div class="d-flex">
                            <div class="mx-2">
                                <h4>
                                    Chat participants
                                </h4>
                                <p>{{ chatInfo.users.length }}</p>
                            </div>
                            <v-btn
                                small
                                icon
                                @click="openDialogParticipant"
                                class="mx-2 mt-1 justify-center"
                                id="probability"
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </div>
                        <div class="d-flex">
                            <div class="mx-2">
                                <h4>
                                    Follow Up Date
                                </h4>
                                <p>{{ chatInfo.followUpDate }}</p>
                            </div>
                            <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        small
                                        icon
                                        class="mx-2 mt-1 justify-center"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <v-date-picker
                                    v-model="chatInfo.followUpDate"
                                    @input="menu = false"
                                    :min="minDate"
                                ></v-date-picker>
                            </v-menu>
                        </div>
                    </v-col>
                    <!-- Participants Dialog -->
                    <v-dialog
                        :retain-focus="false"
                        v-model="addDialog"
                        persistent
                        max-width="400px"
                    >
                        <v-card>
                            <v-card-title class="text-h5"
                                >Add Participant</v-card-title
                            >
                            <v-card-text>
                                <v-form ref="collaborator">
                                    <v-autocomplete
                                        @change="deleteSearch"
                                        :search-input.sync="search"
                                        label="Participant"
                                        v-model="chatUsers"
                                        :items="users"
                                        item-text="name"
                                        chips
                                        item-value="id"
                                        multiple
                                    >
                                    </v-autocomplete>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    text
                                    color="secondary"
                                    @click="closeDialog"
                                >
                                    Close
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    @click="updateParticipants"
                                    text
                                    color="error"
                                    :loading="loading"
                                >
                                    Save
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-spacer
                        v-if="
                            $router.currentRoute.name.includes(
                                'project-quote-follow-up'
                            )
                        "
                    />
                    <v-col
                        cols="12"
                        lg="6"
                        md="6"
                        class="d-flex justify-end pt-0 pb-0"
                    >
                        <div
                            v-for="(doc, i) in documents"
                            :key="i"
                            class="d-flex"
                        >
                            <v-btn
                                v-if="!doc.type.includes('image')"
                                color="grey lighten-3"
                                elevation="0"
                                x-small
                                class="mt-5 mx-2 text-capitalize grey--text text--darken-1  "
                            >
                                <span>{{ doc.name }}</span>
                                <v-icon
                                    @click="deleteDoc(doc)"
                                    class="ml-1"
                                    small
                                >
                                    mdi-close-circle
                                </v-icon>
                            </v-btn>
                            <v-card class="d-flex ma-0 ml-2 pa-1" v-else>
                                <v-img
                                    class="ma-0 pa-0"
                                    max-width="80px"
                                    max-height="64px"
                                    :src="doc.auxURL"
                                >
                                    <v-btn small icon color="white">
                                        <v-icon
                                            class="ma-0 pa-0"
                                            background
                                            @click="deleteDoc(doc)"
                                            small
                                        >
                                            mdi-close-circle
                                        </v-icon>
                                    </v-btn>
                                </v-img>
                            </v-card>
                        </div>
                        <v-btn
                            v-if="!history"
                            icon
                            color="primary"
                            class="mt-2 pt-1 mr-3"
                            @click="loadDocuments = true"
                        >
                            <v-icon>mdi-paperclip</v-icon>
                        </v-btn>
                        <div class="d-flex">
                            <v-chip
                                v-if="event"
                                color="blue"
                                outlined
                                class="white--text text-capitalize mt-3"
                                >{{ event.type }}</v-chip
                            >
                            <v-btn
                                v-if="!history"
                                icon
                                color="primary"
                                class="mt-3 mr-3"
                                @click="openEventForm"
                            >
                                <v-icon>mdi-calendar-plus</v-icon>
                            </v-btn>
                        </div>
                        <v-btn
                            :loading="loading"
                            color="primary"
                            class="mt-2 mr-3 white--text"
                            rounded
                            :disabled="
                                (!event && !message && documents.length == 0) ||
                                    history
                            "
                            @click="createPost"
                        >
                            SEND
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-row>
        <!-- PROJECTION FORM -->
        <v-dialog v-model="projectionForm" persistent max-width="600px">
            <ProjectionForm
                v-if="projectionForm"
                @closeProjectionForm="closeProjectionForm"
                @updateProjection="updateProjection"
                :projection="{
                    probability: this.probability,
                    expectedDate: this.expectedDate,
                }"
            />
        </v-dialog>
        <!-- EVENT FORM -->
        <v-dialog v-model="eventForm" persistent max-width="600px">
            <EventForm
                v-if="eventForm"
                @closeEventForm="closeEventForm"
                @setEvent="setEvent"
                @removeEvent="removeEvent"
                :selectedEvent="selectedEvent"
                :quoteId="id"
                :postId="postId"
            />
        </v-dialog>
        <!-- DOCUMENTS FORM -->
        <v-dialog
            :retain-focus="false"
            v-model="loadDocuments"
            persistent
            max-width="400px"
        >
            <v-card v-if="loadDocuments">
                <v-card-title class="text-h5 text-capitalize">
                    <v-btn
                        class="mr-3"
                        depressed
                        color="primary"
                        icon
                        rounded
                        @click="loadDocuments = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                    Upload Documents</v-card-title
                >
                <v-card-text>
                    <v-form ref="formDoc" v-model="valid">
                        <v-row>
                            <v-col cols="12">
                                <v-file-input
                                    :rules="[rules.documents]"
                                    required
                                    v-model="tempDocuments"
                                    multiple
                                    type="file"
                                    label="File"
                                ></v-file-input>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        class="text-capitalize"
                        :disabled="!valid"
                        @click="addDocuments"
                    >
                        ADD
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import API from '@/services/api'
import { firestore } from '@/services/firebase'
import { saveFile } from '@/services/storage/saveFile'
import _ from 'lodash'
import { mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import { loadImage } from '@/helpers/imageHandler'
//import { storage } from '@/services/firebase'
import { v4 as uuidv4 } from 'uuid'

export default {
    name: 'QuoteFollowUp',
    props: {
        id: {
            type: String,
            required: true,
        },
        quote: {
            type: Object,
            required: true,
        },
        settings: {
            type: Array,
            required: true,
            default: () => [],
        },
        history: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        FollowUpPost: () => import('@/components/Chats/FollowUpPost'),
        EventForm: () => import('@/components/Chats/EventForm'),
        ProjectionForm: () => import('@/components/Chats/ProjectionForm'),
    },
    data: () => {
        return {
            menu2: false,
            mentionSearch: '',
            positionX: 0,
            positionY: 0,
            listener: null,
            loading: false,
            errorMsg: null,
            customToolbar: [
                [{}],
                ['bold', 'italic', 'underline'], // toggled buttons
                [],
                [],
                [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                [], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            ],
            message: '',
            height: 0,
            postsHeight: 0,
            editorHeight: 0,
            posts: [],
            users: [],
            userId: JSON.parse(localStorage.getItem('userId')),
            eventForm: false,
            event: null,
            rules: {
                documents: v => !!v || v.length > 0 || 'Required',
            },
            documents: [],
            loadDocuments: false,
            valid: false,
            tempDocuments: [],
            folderFollowUp: 'follow_up_documents',
            companyId: JSON.parse(localStorage.getItem('company')),
            probability: '',
            expectedDate: new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substr(0, 10),
            minDate: new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substr(0, 10),
            odds: ['High', 'Medium', 'Low'],
            menu: false,
            selectedEvent: undefined,
            postId: undefined,
            editorOption: {
                modules: {
                    toolbar: false,
                    clipboard: {
                        allowed: {
                            tags: ['br', 'img'],
                            attributes: ['src'],
                        },
                        keepSelection: true,
                        substituteBlockElements: true,
                        magicPasteLinks: true,
                        hooks: {
                            uponSanitizeElement(node) {
                                console.log(node)
                            },
                        },
                    },
                },
            },
            projectionForm: false,
            selectedProjection: {},
            folderPictures: 'users_pictures',
            addDialog: false,
            search: '',
            chatInfo: {},
            chatUsers: [],
            mentions: [],
        }
    },
    inject: {
        replaceQuoteLocal: {
            from: 'replaceQuoteLocal',
            default: () => null,
        },
    },
    watch: {
        posts() {
            setTimeout(() => {
                const divMsgs = document.getElementById('chat-container')
                divMsgs.scrollTop = divMsgs.scrollHeight
            }, 1000)
        },
        quote: async function() {
            this.updateSeeMenuAction(true)
        },
        async id() {
            await this.loadPosts()
        },
        'chatInfo.followUpDate': async function(current, old) {
            try {
                if (current != old) {
                    await API.updateChatFromQuote({
                        quoteId: this.chatInfo.id,
                        followUpDate: current,
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
    },
    computed: {
        filteredUsers() {
            if (this.mentionSearch === '') return this.users
            return this.users.filter(user =>
                user.name
                    .toLowerCase()
                    .includes(this.mentionSearch.toLowerCase())
            )
        },
    },
    async mounted() {
        try {
            // retrieve users
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            // retrieve chat info
            this.chatInfo = await API.getChatInfo(this.id)
            // set users
            this.chatUsers = this.chatInfo.users
            // set users data
            this.posts = []
            for (let userId of this.quote.collaborators
                ? this.quote.collaborators
                : []) {
                const index = this.users.findIndex(u => u.id == userId)
                if (
                    index >= 0 &&
                    this.users[index].picture &&
                    !this.users[index].pictureURL
                ) {
                    const pictureURL = await loadImage(
                        `${this.companyId}/${this.folderPictures}`,
                        this.users[index].picture
                    )
                    this.users.splice(index, 1, {
                        ...this.users[index],
                        pictureURL,
                    })
                }
            }
            // load posts
            await this.loadPosts()
            // retrieve projection
            const projection = await API.getProjections({ id: this.quote.id })
            if (projection.probability)
                this.probability =
                    projection.probability[0].toUpperCase() +
                    projection.probability.substring(1)
            if (projection.expectedDate) {
                this.expectedDate = moment
                    .unix(projection.expectedDate._seconds)
                    .format('YYYY-MM-DD')
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    beforeDestroy() {
        this.listener()
        this.listener = null
    },
    methods: {
        ...mapActions(['updateSeeMenuAction']),
        ...mapMutations(['setErrorItems']),
        addMention(user) {
            const editor = this.$refs.quillEditor.quill
            const range = editor.getSelection()
            if (range) {
                const mentionIndex = editor
                    .getText(0, range.index)
                    .lastIndexOf('@')
                editor.deleteText(mentionIndex, range.index - mentionIndex) // Remove '@'
                //editor.insertText(mentionIndex, `@${user.name} `)
                const mentionHtml = `<span style="background-color: #e6f7ff; padding: 0 2px; border-radius: 4px;"><span style="color: #007bff; font-weight: bold;">@${user.name}</span></span><span> </span>`
                editor.clipboard.dangerouslyPasteHTML(mentionIndex, mentionHtml)
                editor.setSelection(range.index + user.name.length + 1) // Move cursor to the end of the mention
                this.menu2 = false
                if (
                    this.mentions.find(mention => mention.id == user.id) ==
                    undefined
                ) {
                    this.mentions.push({
                        id: user.id,
                        name: user.name,
                    })
                }
            }
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[arr.length - 1]),
                n = bstr.length,
                u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            return new File([u8arr], filename, { type: mime })
        },
        imageSize(url) {
            const img = document.createElement('img')

            const promise = new Promise((resolve, reject) => {
                img.onload = () => {
                    // Natural size is the actual image size regardless of rendering.
                    // The 'normal' `width`/`height` are for the **rendered** size.
                    const width = img.naturalWidth
                    const height = img.naturalHeight

                    // Resolve promise with the width and height
                    resolve({ width, height })
                }

                // Reject promise on error
                img.onerror = reject
            })

            // Setting the source makes it start downloading and eventually call `onload`
            img.src = url

            return promise
        },
        async messageHandler() {
            try {
                const editor = this.$refs.quillEditor.quill
                const range = editor.getSelection()
                if (range && range.index > 0) {
                    const text = editor.getText(0, range.index)
                    const mentionIndex = text.lastIndexOf('@')
                    if (mentionIndex !== -1) {
                        const mentionText = text.slice(
                            mentionIndex + 1,
                            range.index
                        )
                        this.mentionSearch = mentionText
                        const bounds = editor.getBounds(range.index)
                        this.positionX = bounds.left
                        this.positionY = window.innerHeight - bounds.bottom
                        this.menu2 = true
                    } else {
                        this.menu2 = false
                    }
                }
                if (this.message.includes('data:image/png;base64')) {
                    let splited = this.message.split('<img src="')
                    let newMessage = splited[0]
                    let base64 = splited[1]
                    if (base64) {
                        splited = base64.includes('" alt="">')
                            ? base64.split('" alt="">')
                            : base64.split('">')
                        base64 = splited[0]
                        newMessage += splited[1]
                    }
                    let name = uuidv4() + '.png'

                    let file = this.dataURLtoFile(base64, name)
                    file.auxURL = URL.createObjectURL(file)
                    const dimensions = await this.imageSize(file.auxURL)
                    file.width = dimensions.width
                    file.height = dimensions.height
                    this.documents.push(file)
                    this.message = newMessage
                    let element = document
                        .getElementsByClassName('ql-editor')
                        .item(0)
                    element.innerHTML = this.message
                    element.blur()
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        closeProjectionForm() {
            this.projectionForm = false
        },
        openProjectionForm() {
            this.projectionForm = true
        },
        async loadPosts() {
            try {
                this.updateSeeMenuAction(true)
                let ChatQuery = firestore
                    .collection('companies')
                    .doc(this.companyId)
                    .collection('chat')
                    .doc(this.id)
                    .collection('messages_v2')
                this.listener = await ChatQuery.onSnapshot(
                    async docSnapshot => {
                        docSnapshot.docChanges().forEach(async change => {
                            const post = Object.assign(change.doc.data(), {
                                id: change.doc.id,
                            })
                            if (change.type === 'added') {
                                this.posts.push(post)
                            }
                            if (change.type === 'modified') {
                                const index = this.posts.findIndex(
                                    p => p.id == post.id
                                )
                                if (index >= 0) {
                                    this.posts.splice(index, 1, post)
                                }
                            }
                            if (change.type === 'removed') {
                                const index = this.posts.findIndex(
                                    p => p.id == post.id
                                )
                                if (index >= 0) {
                                    this.posts.splice(index, 1)
                                }
                            }
                        })
                        this.posts.sort(
                            (a, b) => a.createdOn.seconds - b.createdOn.seconds
                        )
                    }
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        onResize() {
            const subHeader =
                this.$router.currentRoute.name != 'project-quote-follow-up'
                    ? 84
                    : 0
            this.height = window.innerHeight - 146 - subHeader
            this.postsHeight =
                this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.md
                    ? this.height - 198 - 54
                    : this.height - 198
        },
        removeEvent(postId) {
            this.posts.forEach(post => {
                if (post.id === postId) {
                    post.eventId = ''
                    delete post.event
                }
            })
            this.posts = _.cloneDeep(this.posts)
        },
        openEvent(event, postId) {
            this.selectedEvent = event
            this.postId = postId
            this.eventForm = true
        },
        async updateProjection({ probability, expectedDate }) {
            try {
                this.loading = true
                this.errorMsg = null
                this.probability = probability
                this.expectedDate = expectedDate
                await API.projections({
                    id: this.quote.id,
                    probability: this.probability.toLowerCase(),
                    expectedDate: this.expectedDate,
                })
                this.quote.probability = this.probability
                this.quote.expectedDate = this.expectedDate
                this.replaceQuoteLocal(this.id, this.quote)
                // create message
                this.message = `The probability has been changed to ${this.probability}, expected closed at ${this.expectedDate}`
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.projectionForm = false
                await this.createPost()
            }
        },
        viewError(message) {
            this.errorMsg = message
        },
        deleteDoc(doc) {
            this.documents = this.documents.filter(
                d => d.name !== doc.name && d.size !== doc.size
            )
        },
        async addDocuments() {
            this.documents = [...this.documents, ...this.tempDocuments]
            this.tempDocuments = []
            this.loadDocuments = false
            await this.createPost()
        },
        async setEvent(event) {
            this.event = event
            await this.createPost()
        },
        async createPost() {
            let element = document.getElementsByClassName('ql-editor').item(0)
            try {
                let msg = element.innerText.trim()
                if (msg.length > 0 || this.documents.length > 0) {
                    this.loading = true
                    const postToSave = {
                        message: msg || '',
                        userId: this.userId,
                        chatId: this.id,
                        files: this.documents.map(doc => {
                            return {
                                name: doc.name,
                                ...(doc.width
                                    ? { width: doc.width, height: doc.height }
                                    : {}),
                            }
                        }),
                    }

                    if (this.event) {
                        postToSave.eventId = this.event.id
                        if (!postToSave.message) {
                            postToSave.message = 'An event has been created:'
                        }
                    }
                    // save documents
                    const path = `${this.companyId}/${this.folderFollowUp}/${this.id}`
                    for (let doc of this.documents) {
                        await saveFile(doc, path)
                    }
                    // save message
                    if (this.mentions.length > 0) {
                        this.mentions = this.mentions.filter(mention =>
                            postToSave.message.includes(`@${mention.name}`)
                        )
                    }
                    await API.createPost({
                        ...postToSave,
                        ...(this.mentions.length > 0
                            ? { mentions: this.mentions }
                            : {}),
                    })
                    this.message = ''
                    this.event = null
                    this.documents = []
                    const divMsgs = document.getElementById('chat-container')
                    divMsgs.scrollTop = divMsgs.scrollHeight
                } else if (this.message) {
                    msg = this.message.trim()
                    this.loading = true
                    const postToSave = {
                        message: msg || '',
                        userId: this.userId,
                        chatId: this.id,
                        files: this.documents.map(doc => {
                            return {
                                name: doc.name,
                                ...(doc.width
                                    ? { width: doc.width, height: doc.height }
                                    : {}),
                            }
                        }),
                    }

                    if (this.event) {
                        postToSave.eventId = this.event.id
                        if (!postToSave.message) {
                            postToSave.message = 'An event has been created:'
                        }
                    }
                    // save documents
                    const path = `${this.companyId}/${this.folderFollowUp}/${this.id}`
                    for (let doc of this.documents) {
                        await saveFile(doc, path)
                    }
                    // save message
                    if (this.mentions.length > 0) {
                        this.mentions = this.mentions.filter(mention =>
                            postToSave.message.includes(`@${mention.name}`)
                        )
                    }
                    await API.createPost({
                        ...postToSave,
                        ...(this.mentions.length > 0
                            ? { mentions: this.mentions }
                            : {}),
                    })
                    this.message = ''
                    this.event = null
                    this.documents = []
                    const divMsgs = document.getElementById('chat-container')
                    divMsgs.scrollTop = divMsgs.scrollHeight
                } else throw { message: 'Empty message!' }
                this.mentions = []
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openEventForm() {
            this.eventForm = true
        },
        closeEventForm() {
            this.eventForm = false
            this.postId = undefined
            this.selectedEvent = undefined
        },
        openDialogParticipant() {
            this.addDialog = true
        },
        closeDialog() {
            this.addDialog = false
        },
        deleteSearch() {
            this.search = ''
        },
        async updateParticipants() {
            try {
                this.addDialog = false
                await API.updateChat({
                    chatId: this.chatInfo.id,
                    users: this.chatUsers,
                })
                this.chatInfo.users = this.chatUsers
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.posts {
    overflow-y: scroll;
    padding: 0px 0px;
}
#editor {
    height: 88px;
    width: 100%;
}
.usersDiv {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 5px;
}
</style>
