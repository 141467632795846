<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="closeDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            NEW CHILD
        </v-card-title>

        <v-card-text>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="child.description"
                            prepend-icon="mdi-book-open-outline"
                            label="Description *"
                            :rules="[rules.required]"
                            hide-details
                            required
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="5">
                        <v-text-field
                            type="number"
                            v-model="child.percentage"
                            prepend-icon="mdi-percent-outline"
                            label="Percentage *"
                            :rules="[rules.required]"
                            hide-details
                            required
                        />
                    </v-col>
                </v-row>
            </v-form>
            <v-row class="mt-6 mx-2">
                <small v-if="createForm">* indicates required field</small>
            </v-row>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" :loading="loading" @click="saveChild">
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import _ from 'lodash'
import { mapMutations } from 'vuex'
import API from '@/services/api'
import { v4 as uuidv4 } from 'uuid'

export default {
    name: 'TaskChildForm',
    props: {
        taskId: String,
        assignedTo: String,
        child: Object,
        originalChild: Object,
        createForm: Boolean,
        children: {
            type: Array,
            default: () => undefined,
        },
    },
    data() {
        return {
            company: JSON.parse(localStorage.getItem('company')),
            rules: {
                required: v => !!v || 'Required',
                name: v =>
                    /^[a-zA-Z]+ [a-zA-Z]+$/.test(v) || 'Not a valid Name',
            },
            loading: false,
            valid: false,
        }
    },
    async mounted() {},
    watch: {},
    computed: {
        childDiff: function() {
            if (!this.createForm) {
                return this.objDiff(this.originalChild, this.child)
            } else {
                return null
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        closeDialog() {
            this.$emit('closeDialog')
        },
        objDiff(origObj, newObj) {
            function changes(newObj, origObj) {
                let arrayIndexCounter = 0

                return _.transform(newObj, function(result, value, key) {
                    if (!_.isEqual(value, origObj[key])) {
                        const resultKey = _.isArray(origObj)
                            ? arrayIndexCounter++
                            : key
                        result[resultKey] =
                            _.isObject(value) && _.isObject(origObj[key])
                                ? changes(value, origObj[key])
                                : value
                    }
                })
            }
            return changes(newObj, origObj)
        },
        saveChild() {
            if (this.createForm) {
                this.createChild()
            } else {
                this.updateChild()
            }
        },
        async updateChild() {
            try {
                this.loading = true
                let child = undefined
                if (this.children != undefined) {
                    child = {
                        id: this.child.id,
                        ...this.childDiff,
                    }
                } else {
                    child = await API.updateTaskChild(this.child.id, {
                        taskId: this.taskId,
                        assignedTo: this.assignedTo,
                        notificationId: 'rCJFinVt7UiC6uTysz2i',
                        ...this.childDiff,
                    })
                }
                this.$emit('replaceChild', child)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async createChild() {
            try {
                this.loading = true
                let child = undefined
                if (this.children != undefined) {
                    child = {
                        id: uuidv4().substring(0, 8),
                        done: false,
                        ...this.child,
                    }
                    child.percentage = Number(child.percentage)
                    this.children.push(child)
                } else {
                    child = await API.createTaskChild({
                        taskId: this.taskId,
                        assignedTo: this.assignedTo,
                        notificationId: 'rCJFinVt7UiC6uTysz2i',
                        ...this.child,
                    })
                }
                this.$emit('addChild', child)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
