<template>
    <div class="error_box">
        <Error
            v-for="item in errorItems"
            :key="item.id"
            :message="item.message"
            :errorId="item.id"
            @closeError="closeError"
        />
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
    name: 'Errors',
    data: () => ({
        loading: false,
        items: undefined,
    }),
    components: {
        Error: () => import('./Error.vue'),
    },
    computed: {
        errorItems() {
            return this.getErrorItems()
        },
    },
    methods: {
        ...mapGetters(['getErrorItems', 'getTimeOutId']),
        ...mapMutations([
            'setErrorItems',
            'setTimeOutId',
            'cleanErrors',
            'updateErrorItems',
        ]),
        closeError(errorId) {
            this.updateErrorItems(errorId)
        },
    },
}
</script>

<style>
.error_box {
    position: absolute;
    bottom: 0;
    right: 0;
}
</style>
