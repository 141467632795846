<template>
    <div v-resize="onResize" class="fill-height pa-4" ref="container">
        <!-- https://www.vue2editor.com/ -->
        <HeaderImages
            v-if="idSettingQuote && !history"
            :quote="quote"
            :id="idSettingQuote"
            ref="headerImages"
        />

        <v-row
            v-if="!history"
            class="align-center mt-5 font-weight-bold"
            ref="title"
        >
            <v-col>
                <span>Commercial Terms</span>
            </v-col>
        </v-row>
        <vue-editor
            ref="editor"
            :style="`height:${this.height}px`"
            :disabled="quote.status !== 'OPEN' && quote.status !== 'APPROVAL'"
            class="mt-2"
            v-if="settingCommercial && !history"
            @focus="onEditorFocus"
            @blur="onEditorBlur"
            :editor-toolbar="customToolbar"
            v-model="settingCommercial.terms"
        />
        <vue-editor
            :editor-toolbar="customToolbar"
            class="mt-2"
            v-if="quote && history"
            v-model="quote.commercialTerms"
        />
    </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import API from '@/services/api'
import HeaderImages from '@/components/Quotes/HeaderImages'
import { mapState, mapActions } from 'vuex'
import { storage } from '@/services/firebase'
import moment from 'moment'

export default {
    name: 'QuoteCommercialTerms',
    props: {
        id: String,
        quote: Object,
        settings: Array,
        costVersions: Object,
        costs: Array,
        history: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        VueEditor,
        HeaderImages,
    },
    inject: {
        replaceQuoteLocal: {
            from: 'replaceQuoteLocal',
            default: () => null,
        },
    },
    data() {
        return {
            settingCommercial: null,
            terms: null,
            idSettingQuote: null,
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                [{ align: '' }, { align: 'center' }, { align: 'right' }],
                ['blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            ],
            folderClientsPictures: 'clients_pictures',
            folderUsersPictures: 'users_pictures',
            companyId: JSON.parse(localStorage.getItem('company')),
            height: 0,
        }
    },
    computed: {
        ...mapState([
            'saveBtn',
            'commercialTerms',
            'clients',
            'contacts',
            'users',
        ]),
    },
    watch: {
        quote: async function() {
            this.updateSeeMenuAction(false)
            await this.setSettings()
            if (this.contacts.length === 0) {
                await this.getContacts()
            }
            if (this.users.length === 0) {
                await this.getUsers()
            }
            this.updateSeeMenuAction(true)
        },
        settings: async function() {
            if (!this.commercialTerms) {
                await this.setSettings()
            }
        },
        'settingCommercial.terms': function() {
            this.quote.commercialTerms = this.settingCommercial.terms
            //this.replaceQuoteLocal(this.quote.id, this.quote)
        },
    },
    async created() {
        this.updateSeeMenuAction(false)
        if (this.settings) {
            await this.setSettings()
        }
        this.updateSeeMenuAction(true)
    },
    methods: {
        onResize() {
            this.height = window.innerHeight - 220
        },
        ...mapActions([
            'quoteChangesAction',
            'addTermsAction',
            'addContactsAction',
            'addUsersAction',
            'updateSeeMenuAction',
        ]),
        async onEditorFocus() {
            await this.quoteChangesAction(false)
        },
        async getCommercialTerms() {
            try {
                if (this.quote.commercialTerms) {
                    this.settingCommercial = {
                        terms: this.quote.commercialTerms,
                    }
                } else {
                    const {
                        data: { commercialTerms },
                    } = await API.getCommercialTerms(this.idSettingQuote)
                    await this.addTermsAction(commercialTerms)
                    if (!this.quote.client) {
                        const {
                            data: { clients },
                        } = await API.getClient({
                            clientId: this.quote.clientId,
                        })
                        this.quote.client = clients
                    }
                    if (this.quote.client.language == 'English') {
                        this.settingCommercial = this.commercialTerms.find(
                            x => x.id == 'english'
                        )
                    } else {
                        this.settingCommercial = this.commercialTerms.find(
                            x => x.id == 'spanish'
                        )
                    }
                    if (this.settingCommercial.terms) {
                        this.quote.commercialTerms = this.settingCommercial.terms
                    }
                }
            } catch (error) {
                this.error = true
                this.errorMsg = error.message
            } finally {
                this.loading = false
            }
        },
        async onEditorBlur() {
            await this.quoteChangesAction(true)
        },
        async setSettings() {
            const indexQuotes = this.settings.findIndex(
                u => u.name === 'Quotes'
            )
            this.idSettingQuote = this.settings[indexQuotes].id
            await this.getCommercialTerms()
        },
        async getContacts() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const {
                    data: { contacts },
                } = await API.getContacts(this.companyId)
                for (const contact of contacts) {
                    if (contact.picture) {
                        const path = `${this.companyId}/${this.folderClientsPictures}`
                        const storageRef = storage().ref(path)
                        const profilePicRef = storageRef.child(contact.picture)
                        await profilePicRef
                            .getDownloadURL()
                            .then(url => {
                                contact.profilePicURL = url
                            })
                            .catch(error => {
                                contact.profilePicRef = ''
                                console.log(error)
                            })
                    }
                }
                this.addContactsAction(contacts)
            } catch (error) {
                this.error = true
                this.errorMsg = error.message
            } finally {
                this.loading = false
            }
        },

        getUsers: async function() {
            try {
                this.error = false
                this.errorMsg = null
                this.loading = true
                const {
                    data: { users },
                } = await API.getUsers(this.companyId)
                for (const user of users) {
                    if (user.picture) {
                        const path = `${this.companyId}/${this.folderUsersPictures}`
                        const storageRef = storage().ref(path)
                        const profilePicRef = storageRef.child(user.picture)
                        user.profilePicURL = await profilePicRef.getDownloadURL()
                    }
                    user.createdOn = moment
                        .unix(user.createdOn._seconds)
                        .format('MMM/DD/YYYY HH:mm')
                }
                this.addUsersAction(users)
            } catch (error) {
                this.error = true
                this.errorMsg = error.message
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style scoped>
.quillWrapper {
    height: 100%;
}
.quillWrapper >>> .ql-toolbar {
    border: none;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.quillWrapper >>> .ql-container {
    height: calc(100% - 48px);
    border: none;
}
</style>
