<template>
    <div>
        <!-- New cost -->
        <v-row no-gutters class="align-center font-weight-bold">
            <v-col cols="8" class="mt-n1">
                <span>General Terms</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-end">
                <v-btn
                    v-if="
                        (quote.status === 'OPEN' ||
                            quote.status === 'APPROVAL') &&
                            !history
                    "
                    color="primary"
                    dark
                    class="mb-2 mr-0 rounded-xl"
                    text
                    @click="openImportQuotes"
                >
                    <v-icon left>mdi-import</v-icon>
                    Import
                </v-btn>
                <v-dialog v-model="importDialog" max-width="800px">
                    <ImportCosts
                        v-if="importDialog"
                        :quote="quote"
                        @importCosts="importCosts"
                        @close="closeImportQuotes"
                    />
                </v-dialog>
                <v-dialog
                    :retain-focus="false"
                    v-model="createDialogNewCost"
                    persistent
                    max-width="1200px"
                    :fullscreen="$vuetify.breakpoint.mobile"
                >
                    <NewQuoteCostForm
                        v-if="
                            (quote.status === 'OPEN' ||
                                quote.status === 'APPROVAL') &&
                                !history &&
                                createDialogNewCost
                        "
                        :originalCost="{}"
                        :createCostForm="createCostForm"
                        title="New Item"
                        :quote="quote"
                        :costs="costs"
                        :cost="newItemCost"
                        :loading="loading"
                        :settings="settings"
                        :alternative="alternative"
                        :action="action"
                        @saveAlternative="saveAlternative"
                        @closeDialog="closeDialog"
                        @createCosts="createCosts"
                        @updateCosts="updateCosts"
                    />
                </v-dialog>
            </v-col>
        </v-row>

        <!-- General terms -->
        <v-row class="pb-2" no-gutters>
            <v-col
                cols="4"
                xl="1"
                lg="1"
                md="1"
                sm="1"
                xs="1"
                class="d-flex align-center"
            >
                <span>Currency</span>
            </v-col>
            <v-col
                v-if="quote"
                cols="4"
                lg="2"
                md="2"
                sm="1"
                xs="1"
                class="d-flex align-center"
            >
                <v-dialog v-model="exchangeForm" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                        <span>{{ quote.currency + ' ' + quote.exchange }}</span>
                        <v-btn
                            v-if="
                                (quote.status === 'OPEN' ||
                                    quote.status === 'APPROVAL') &&
                                    !history
                            "
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="openExchangeForm"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <v-card :loading="loading">
                        <v-card-title>
                            <span class="text-h5">Update Exchange</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="6">
                                        <v-autocomplete
                                            :disabled="
                                                (quote.status !== 'OPEN' &&
                                                    quote.status !==
                                                        'APPROVAL') ||
                                                    history
                                            "
                                            flat
                                            hide-details
                                            v-model="temporaryCurrency"
                                            :items="itemsCurrency"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            :disabled="
                                                (quote.status !== 'OPEN' &&
                                                    quote.status !==
                                                        'APPROVAL') ||
                                                    history
                                            "
                                            flat
                                            prefix="$"
                                            hide-details
                                            v-model="temporaryExchange"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                color="red"
                                text
                                @click="exchangeForm = false"
                            >
                                Cancel
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                v-if="
                                    (quote.status === 'OPEN' ||
                                        quote.status === 'APPROVAL') &&
                                        !history
                                "
                                color="blue darken-1"
                                rounded
                                depressed
                                dark
                                :loading="loading"
                                @click="updateExchange"
                            >
                                Update
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col
                cols="4"
                xl="1"
                lg="1"
                md="1"
                sm="1"
                xs="1"
                class="d-flex align-center"
            >
                <span>Commissions</span>
            </v-col>

            <v-col
                cols="8"
                xl="11"
                lg="11"
                md="11"
                sm="11"
                xs="11"
                v-if="quote"
                class="d-flex flex-wrap align-center"
            >
                <v-card
                    flat
                    v-for="(com, i) in quote.commissions"
                    :key="i"
                    @click="openDialogDelete(com)"
                >
                    <div class="d-flex flex-column  ma-4">
                        <span class="text-singleline my-2">{{
                            com.details
                        }}</span>
                        <span class="text-singleline"
                            >{{ com.percentage }}%</span
                        >
                    </div>
                </v-card>
                <v-btn
                    v-if="
                        (quote.status === 'OPEN' ||
                            quote.status === 'APPROVAL') &&
                            !history
                    "
                    @click="openDialogCommissions"
                    depressed
                    class="ml-2"
                    fab
                    x-small
                    color="primary"
                >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>

                <CommissionForm
                    v-if="
                        (quote.status === 'OPEN' ||
                            quote.status === 'APPROVAL') &&
                            !history
                    "
                    v-model="dialogCommissions"
                    title="Add Commission"
                    :createForm="true"
                    @closeDialog="closeDialogCommissions"
                    @addCommission="addCommissions"
                />
                <CommissionForm
                    v-if="
                        (quote.status === 'OPEN' ||
                            quote.status === 'APPROVAL') &&
                            !history
                    "
                    v-model="deleteCommissions"
                    title="Commission"
                    :createForm="false"
                    @closeDialog="closeDialogDelete"
                    :commission="comToDelete"
                    @deleteCommission="deleteCommission"
                />
            </v-col>
        </v-row>

        <v-data-table
            :loading="loading"
            :headers="headers"
            class="elevation-0 mt-8"
            :items="costsOnTable"
            :mobile-breakpoint="0"
            hide-default-footer
            disable-pagination
        >
            <template v-slot:top>
                <v-toolbar flat style="background-color: #eeeeee !important">
                    <v-toolbar-title>ITEMS</v-toolbar-title>
                    <v-btn icon small class="ml-2" @click="createXLSX"
                        ><v-icon>mdi-download</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="
                            (quote.status === 'OPEN' ||
                                quote.status === 'APPROVAL') &&
                                !history
                        "
                        depressed
                        color="primary"
                        @click="newCost"
                        rounded
                        class="ml-7"
                    >
                        <v-icon left>mdi-plus</v-icon>
                        <span>New Item</span>
                    </v-btn>
                </v-toolbar>
            </template>
            <!--ITEMS-->
            <template v-slot:body="props">
                <draggable
                    :list="costsOnTable"
                    tag="tbody"
                    @change="onMoveCallback"
                    handle=".handle"
                >
                    <tr
                        v-for="(item, index) in props.items"
                        :key="index"
                        @mouseover="handle = item.id"
                        @mouseleave="handle = undefined"
                    >
                        <td class="handle">
                            <div class="d-flex justify-center">
                                <v-btn
                                    small
                                    icon
                                    :class="{ invisible: item.id != handle }"
                                    style="cursor: grab !important"
                                >
                                    <v-icon>mdi-pan-vertical</v-icon>
                                </v-btn>
                            </div>
                        </td>
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.reference }}</td>
                        <td>{{ item.category || '' }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>
                            {{
                                new Intl.NumberFormat('de-DE', {
                                    style: 'currency',
                                    currency: quote.currency,
                                    maximumFractionDigits: 2,
                                }).format(item.total2)
                            }}
                        </td>
                        <td>
                            {{ itemMargin(item) }}
                        </td>
                        <td>
                            {{ itemGrossMargin(item) }}
                        </td>
                        <td>
                            {{
                                new Intl.NumberFormat('de-DE', {
                                    style: 'currency',
                                    currency: quote.currency,
                                }).format(
                                    item.salePrice /
                                        quote.exchange /
                                        item.quantity
                                )
                            }}
                            / {{ item.measure }}
                        </td>
                        <td>
                            {{
                                new Intl.NumberFormat('de-DE', {
                                    style: 'currency',
                                    currency: quote.currency,
                                    maximumFractionDigits: 2,
                                }).format(item.salePrice2)
                            }}
                        </td>
                        <td class="d-flex justify-center pt-2">
                            <v-btn
                                v-if="
                                    $router.currentRoute.name !=
                                        'estimating-plan'
                                "
                                icon
                                small
                                @click.stop="editItem(item)"
                            >
                                <v-icon
                                    v-if="
                                        quote.status === 'OPEN' ||
                                            quote.status === 'APPROVAL'
                                    "
                                    small
                                    >mdi-pencil</v-icon
                                >
                                <v-icon v-else small>mdi-eye-outline</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                small
                                @click.stop="deleteItem(item)"
                                :disabled="
                                    (quote.status !== 'OPEN' &&
                                        quote.status !== 'APPROVAL') ||
                                        history
                                "
                            >
                                <v-icon small>mdi-trash-can</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                small
                                @click.stop="copyItem(item)"
                                :disabled="
                                    (quote.status !== 'OPEN' &&
                                        quote.status !== 'APPROVAL') ||
                                        history
                                "
                            >
                                <v-icon small>mdi-content-copy</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                small
                                @click.stop="openTaskDialog(item)"
                                :disabled="
                                    (quote.status !== 'OPEN' &&
                                        quote.status !== 'APPROVAL') ||
                                        history
                                "
                                v-if="createTask"
                            >
                                <v-icon small
                                    >mdi-plus-box-multiple-outline</v-icon
                                >
                            </v-btn>
                        </td>
                    </tr>
                    <tr class="costs-table-footer">
                        <td></td>
                        <td></td>
                        <td class="font-weight-bold text-left">TOTALS</td>
                        <td></td>
                        <td></td>
                        <td class="font-weight-bold text-left">
                            {{
                                new Intl.NumberFormat('de-DE', {
                                    style: 'currency',
                                    currency: quote.currency,
                                    maximumFractionDigits: 2,
                                }).format(footerTotal.cost)
                            }}
                        </td>
                        <td class="font-weight-bold text-left">
                            {{ footerTotal.margin.toFixed(2) + '%' }}
                        </td>
                        <td class="font-weight-bold text-left">
                            {{
                                new Intl.NumberFormat('de-DE', {
                                    style: 'currency',
                                    currency: quote.currency,
                                    maximumFractionDigits: 2,
                                }).format(footerTotal.grossMargin)
                            }}
                        </td>
                        <td></td>
                        <td class="font-weight-bold text-left">
                            {{
                                new Intl.NumberFormat('de-DE', {
                                    style: 'currency',
                                    currency: quote.currency,
                                    maximumFractionDigits: 2,
                                }).format(
                                    totals == 0 ? quote.totalCosts : totals
                                )
                            }}
                        </td>
                        <td></td>
                    </tr>
                </draggable>
            </template>
        </v-data-table>

        <v-data-table
            :loading="loading"
            :headers="headersAlternatives"
            class="elevation-0 mt-8"
            :items="currentAlternatives"
            :mobile-breakpoint="0"
        >
            <template v-slot:top>
                <v-toolbar flat style="background-color: #eeeeee !important">
                    <v-toolbar-title>ALTERNATES</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="
                            (quote.status === 'OPEN' ||
                                quote.status === 'APPROVAL') &&
                                !history
                        "
                        depressed
                        color="primary"
                        @click="openAddAlternative = true"
                        rounded
                        class="ml-7"
                    >
                        <v-icon left>mdi-plus</v-icon>
                        <span>ADD ALTERNATE</span>
                    </v-btn>
                </v-toolbar>
            </template>

            <template v-slot:[`item.salePrice2`]="{ item }">
                {{
                    new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: quote.currency,
                        maximumFractionDigits: 2,
                    }).format(item.salePrice2)
                }}
            </template>
            <template v-slot:[`item.price`]="{ item }">
                {{
                    new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: quote.currency,
                        maximumFractionDigits: 2,
                    }).format(getItemsPrice(item))
                }}
            </template>
            <template v-slot:[`item.difference`]="{ item }">
                {{ calDifference(item) }}
            </template>
            <template v-slot:[`item.pricePerQuantity2`]="{ item }">
                {{
                    new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: quote.currency,
                    }).format(item.salePrice / quote.exchange / item.quantity)
                }}
                / {{ item.measure }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <v-menu rounded offset-y>
                    <template v-slot:activator="{ attrs, on }">
                        <v-btn icon v-bind="attrs" v-on="on" small depressed>
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item
                            v-if="
                                $router.currentRoute.name != 'estimating-plan'
                            "
                            @click.stop="editItem(item)"
                        >
                            <v-list-item-icon>
                                <v-icon
                                    v-if="
                                        quote.status === 'OPEN' ||
                                            quote.status === 'APPROVAL'
                                    "
                                    small
                                    >mdi-pencil</v-icon
                                >
                                <v-icon v-else small>mdi-eye-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                {{
                                    quote.status === 'OPEN' ||
                                    quote.status === 'APPROVAL'
                                        ? 'Update'
                                        : 'View'
                                }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            @click.stop="
                                comToDelete = item
                                openDeleteDialog = true
                            "
                            :disabled="
                                (quote.status !== 'OPEN' &&
                                    quote.status !== 'APPROVAL') ||
                                    history
                            "
                        >
                            <v-list-item-icon class="">
                                <v-icon small>mdi-trash-can</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                Delete
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            :disabled="
                                (quote.status !== 'OPEN' &&
                                    quote.status !== 'APPROVAL') ||
                                    history
                            "
                            @click.stop="replaceCosts(item)"
                        >
                            <v-list-item-icon>
                                <v-icon small>mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                Approve
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            :disabled="
                                (quote.status !== 'OPEN' &&
                                    quote.status !== 'APPROVAL') ||
                                    history
                            "
                            @click.stop="openChangeBID(item)"
                        >
                            <v-list-item-icon>
                                <v-icon small>mdi-swap-horizontal</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                Change BID
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>

        <v-dialog
            :retain-focus="false"
            v-model="categoryField"
            max-width="400px"
            persistent
        >
            <v-card v-if="categoryField">
                <v-card-title
                    ><v-btn
                        class="mr-3"
                        depressed
                        color="primary"
                        icon
                        rounded
                        small
                        @click="categoryField = false"
                    >
                        <v-icon>mdi-close</v-icon> </v-btn
                    >ADD CATEGORY</v-card-title
                >
                <v-divider class="ml-4 mr-5"></v-divider>
                <v-card-text class="pb-0">
                    <v-row class="pt-3">
                        <v-col cols="12">
                            The selected alternative does not have an associated
                            category.
                        </v-col>
                    </v-row>
                    <v-form v-model="valid" ref="form">
                        <v-row no-gutters class="my-2">
                            <v-col cols="12">
                                <v-autocomplete
                                    flat
                                    dense
                                    solo
                                    required
                                    hide-details
                                    placeholder="Cost category for invoicing"
                                    :rules="[rules.required]"
                                    class="text-body-2"
                                    :items="categories"
                                    item-text="name"
                                    item-value="name"
                                    v-model="cloneAlternate.category"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pt-0">
                    <v-row no-gutters>
                        <v-col cols="12" class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="replaceCosts(cloneAlternate)"
                                text
                                color="primary"
                                :disabled="!valid"
                            >
                                SAVE
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- alternative dialog -->
        <v-dialog
            :retain-focus="false"
            v-model="openAddAlternative"
            persistent
            max-width="400px"
        >
            <v-card v-if="openAddAlternative">
                <v-card-title class="text-h5">ADD ALTERNATE</v-card-title>
                <v-card-text>
                    <v-form v-model="valid" ref="form">
                        <v-row no-gutters class="my-2">
                            <v-col cols="12">
                                <v-text-field
                                    label="Name *"
                                    v-model="alternativeName"
                                    hide-details
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="my-2">
                            <v-col cols="12">
                                <v-select
                                    v-model="alternatives"
                                    :items="currentItems"
                                    label="Select"
                                    chips
                                    hint="Select one or more items."
                                    persistent-hint
                                    item-text="reference"
                                    item-value="id"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="my-2">
                            <v-col cols="12">
                                <v-checkbox
                                    v-model="copyItems"
                                    label="Copy costs."
                                    hide-details
                                    :disabled="
                                        !alternatives ||
                                            alternatives.length == 0
                                    "
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-row no-gutters>
                        <v-col cols="12" class="d-flex">
                            <v-btn
                                text
                                color="secondary"
                                @click="closeAddAlternative"
                            >
                                CANCEL
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="addAlternatives"
                                text
                                color="primary"
                                :disabled="!valid"
                            >
                                CREATE
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- delete cost -->

        <v-dialog
            :retain-focus="false"
            v-model="deleteDialog"
            persistent
            max-width="400px"
        >
            <v-card>
                <v-card-title class="text-h5">Delete Cost</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this cost?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="deleteDialog = false">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="deleteCosts" text color="error">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- delete alternative -->

        <v-dialog
            :retain-focus="false"
            v-model="openDeleteDialog"
            persistent
            max-width="400px"
        >
            <v-card>
                <v-card-title class="text-h5">Delete Alternative</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this alternative?
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="openDeleteDialog = false"
                    >
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="deleteAlternative" text color="error">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            :retain-focus="false"
            v-model="editDialog"
            persistent
            max-width="1200px"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <NewQuoteCostForm
                v-if="editDialog && quote && editDialog"
                :copyCost="copyCost"
                title="Edit Item"
                :quote="quote"
                :costs="costs"
                :editAlternative="editAlternative"
                :originalCost="originalCost"
                :cost="this.costToEdit"
                :settings="settings"
                :alternative="alternative"
                :action="action"
                @closeDialog="closeEditItem"
                :loading="loading"
                @updateCosts="updateCosts"
                @updateAlternativeCosts="updateAlternativeCosts"
                @saveAlternative="saveAlternative"
            />
        </v-dialog>
        <!-- task  -->
        <v-dialog
            :retain-focus="false"
            v-model="openTaskForm"
            persistent
            max-width="640px"
        >
            <TaskForm
                v-if="openTaskForm"
                :task="selectedTask"
                :originalTask="selectedTask"
                :createForm="createForm"
                :fromCosts="true"
                @closeDialog="closeTaskDialog"
            />
        </v-dialog>
        <v-alert
            :type="action.type"
            :color="action.color"
            style="position: absolute; bottom: 0; right: 0;"
            dismissible
            v-if="action.type == 'success' && action.active"
        >
            {{ action.message }}
        </v-alert>
        <!--CHANGE BID-->
        <v-dialog
            :retain-focus="false"
            v-model="changeBIDDialog"
            persistent
            max-width="640px"
        >
            <ChangeBID
                v-if="changeBIDDialog"
                :alternate="selectedAlternate"
                :costs="costs"
                :quoteId="quote.id"
                @changeBID="changeBID"
                @close="closeChangeBID"
            />
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import CommissionForm from '@/components/Quotes/CommissionForm'
import API from '@/services/api'
import NewQuoteCostForm from '@/components/Quotes/NewQuoteCostForm'
import _ from 'lodash'
import moment from 'moment'
import { storage } from '@/services/firebase'
import convert from 'convert-units'
import * as XLSX from 'xlsx/xlsx.mjs'
import Draggable from 'vuedraggable'
import TaskForm from '@/components/Tasks/TaskForm.vue'
import ImportCosts from '../../components/Quotes/ImportCosts.vue'

export default {
    name: 'QuoteCosts',
    components: {
        NewQuoteCostForm,
        CommissionForm,
        Draggable,
        TaskForm,
        ImportCosts,
        ChangeBID: () => import('@/components/Quotes/ChangeBID.vue'),
    },
    props: {
        id: String,
        quote: Object,
        settings: Array,
        costs: Array,
        costVersions: Object,
        history: {
            type: Boolean,
            default: false,
        },
    },
    // Function provided from the mainboard
    inject: {
        filterQuotes: {
            from: 'filterQuotes',
            default: null,
        },
        spreadCosts: {
            from: 'spreadCosts',
            default: null,
        },
        replaceQuoteLocal: {
            from: 'replaceQuoteLocal',
            default: () => null,
        },
    },
    data() {
        return {
            selectedAlternate: undefined,
            changeBIDDialog: false,
            createCostForm: false,
            createTask: false,
            handle: undefined,
            createForm: true,
            selectedTask: {},
            openTaskForm: false,
            openDeleteDialog: false,
            editAlternative: false,
            alternative: false,
            alternativeName: '',
            copyItems: false,
            items: [
                { id: 1, name: 'primero' },
                { id: 2, name: 'segundo' },
            ],
            alternatives: [],
            openAddAlternative: false,
            headers: [
                {
                    text: '',
                    value: 'handle',
                    sortable: false,
                    align: 'left',
                    class: 'grey lighten-3',
                    width: 10,
                },
                {
                    text: '#',
                    value: 'index',
                    class: 'grey lighten-3',
                },
                {
                    text: 'DESCRIPTION',
                    value: 'reference',
                    class: 'grey lighten-3',
                },
                {
                    text: 'CATEGORY',
                    value: 'category',
                    class: 'grey lighten-3',
                },
                {
                    text: 'QTY',
                    value: 'quantity',
                    class: 'grey lighten-3',
                },
                {
                    text: 'COST',
                    value: 'cost',
                    class: 'grey lighten-3',
                },
                {
                    text: 'MARGIN',
                    value: 'margin',
                    class: 'grey lighten-3',
                },
                {
                    text: 'GROSS MARGIN',
                    value: 'grossMargin',
                    class: 'grey lighten-3',
                },
                {
                    text: 'PRICE PER QUANTITY',
                    value: 'pricePerQuantity2',
                    class: 'grey lighten-3',
                },
                {
                    text: 'SALE PRICE',
                    value: 'salePrice2',
                    class: 'grey lighten-3',
                },
                {
                    text: 'ACTION',
                    value: 'actions',
                    sortable: false,
                    align: 'center',
                    class: 'grey lighten-3',
                },
            ],
            headersAlternatives: [
                {
                    text: 'DESCRIPTION',
                    value: 'reference',
                    class: 'grey lighten-3',
                    width: 300,
                    sortable: false,
                },
                {
                    text: 'CATEGORY',
                    value: 'category',
                    class: 'grey lighten-3',
                    width: 150,
                    sortable: false,
                },
                {
                    text: 'QTY',
                    value: 'quantity',
                    class: 'grey lighten-3',
                    width: 80,
                },
                {
                    text: 'PRICE PER QUANTITY',
                    value: 'pricePerQuantity2',
                    class: 'grey lighten-3',
                    width: 200,
                },
                {
                    text: 'SALE PRICE',
                    value: 'salePrice2',
                    class: 'grey lighten-3',
                    width: 150,
                },
                {
                    text: 'BASE BID',
                    value: 'price',
                    class: 'grey lighten-3',
                    width: 150,
                },
                {
                    text: 'DELTA',
                    value: 'difference',
                    class: 'grey lighten-3',
                    width: 150,
                },
                {
                    text: 'ACTION',
                    value: 'actions',
                    sortable: false,
                    align: 'center',
                    class: 'grey lighten-3',
                    width: 150,
                },
            ],
            cloneAlternate: null,
            categories: [],
            categoryField: false,
            totalCosts: 0,
            loading: false,
            costsOnTable: [],
            itemsCurrency: [],
            globalCurrency: null,
            exchanges: [],
            error: null,
            errorMsg: null,
            deleteDialog: false,
            copyCost: false,
            createDialogNewCost: false,
            settingExchange: [],
            editDialog: false,
            costToEdit: null,
            exchange: null,
            copyExchange: null,
            copyCurrency: null,
            dialogCommissions: false,
            deleteCommissions: false,
            originalCost: null,
            comToDelete: {},
            sumCommissions: 0,
            exchangeForm: false,
            temporaryExchange: '',
            temporaryCurrency: '',
            newItemCost: {
                totalSections: [],
                sections: [],
                total: 0,
                total2: 0,
                totalMargin2: 0,
                commission2: 0,
                quantity2: 0,
                salePrice2: 0,
                pricePerQuantity2: 0,
                margin: 40,
                measure: 'ft2',
                totalMargin: 0,
                commission: 0,
                salePrice: 0,
                pricePerQuantity: 0,
                american: {
                    tw: 0,
                    wa: 0,
                    cw: 0,
                    pw: 0,
                    ca: 0,
                },
                international: {
                    tw: 0,
                    wa: 0,
                    cw: 0,
                    pw: 0,
                    ca: 0,
                },
            },
            folderClientsPictures: 'clients_pictures',
            folderUsersPictures: 'users_pictures',
            companyId: JSON.parse(localStorage.getItem('company')),
            user: JSON.parse(localStorage.getItem('userId')),
            quantityInternational: 0,
            importDialog: false,
            importedQuote: null,
            quotes: [],
            importLoading: false,
            importError: false,
            importedCosts: [],
            oldQuote: Object.assign({}, this.quote),
            valid: false,
            rules: { required: v => !!v || 'The value is required' },
            currentAlternatives: [],
            isMounted: false,
            arItems: undefined,
            action: { active: false },
        }
    },
    watch: {
        quote: async function() {
            this.updateSeeMenuAction(false)
            this.copyTerms()
            if (!this.quote.client) {
                const {
                    data: { clients },
                } = await API.getClient({ clientId: this.quote.clientId })
                this.quote.client = clients
            }
            if (this.contacts.length === 0) {
                await this.getContacts()
            }
            if (this.users.length === 0) {
                await this.getUsers()
            }
            if (!this.quote.mainContact) {
                this.quote.mainContact = this.users.find(
                    user => this.quote.userId == user.id
                )
            }
            this.updateSeeMenuAction(true)
        },
        action: function() {
            if (this.action.active) {
                setTimeout(() => {
                    this.action.active = false
                }, 5000)
            }
        },
    },
    computed: {
        ...mapState(['contacts', 'users', 'clients']),
        ...mapGetters(['getAlternatives']),
        totals: function() {
            return this.totalCosts
        },
        currentExchange: function() {
            if (this.quote) {
                return this.quote.exchange
            } else {
                return 0
            }
        },
        currentItems() {
            if (this.costsOnTable && this.costsOnTable.length > 0) {
                return this.costsOnTable
            } else {
                return this.costs
            }
        },
        footerTotal() {
            let totals = {
                cost: 0,
                margin: 0,
                grossMargin: 0,
            }

            let totalCommission = 0
            if (this.quote.commissions) {
                this.quote.commissions.forEach(commission => {
                    totalCommission += parseFloat(commission.percentage) / 100
                })
            }

            this.costsOnTable.forEach(cost => {
                totals.cost += cost.total2
                totals.grossMargin +=
                    cost.salePrice2 -
                    cost.total2 -
                    cost.salePrice2 * totalCommission
            })
            totals.margin = (totals.grossMargin * 100) / this.quote.totalCosts

            return totals
        },
    },
    methods: {
        ...mapMutations(['setAlternatives', 'setErrorItems']),
        closeChangeBID() {
            this.selectedAlternate = {}
            this.changeBIDDialog = false
        },
        openChangeBID(item) {
            this.selectedAlternate = _.clone(item)
            this.changeBIDDialog = true
        },
        async changeBID(cost) {
            try {
                const index = this.currentAlternatives.findIndex(
                    a => a.id == cost.id
                )
                if (index >= 0) {
                    this.costToEdit = {
                        ...this.currentAlternatives[index],
                        ...cost,
                    }
                    await this.updateAlternativeCosts()
                }
                this.closeChangeBID()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        itemGrossMargin(item) {
            let totalCommission = 0
            if (this.quote.commissions) {
                this.quote.commissions.forEach(commission => {
                    totalCommission += parseFloat(commission.percentage) / 100
                })
            }

            const grossMargin =
                item.salePrice2 -
                item.total2 -
                item.salePrice2 * totalCommission

            return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: this.quote.currency,
                maximumFractionDigits: 2,
            }).format(grossMargin)
        },
        itemMargin(item) {
            let totalCommission = 0
            if (this.quote.commissions) {
                this.quote.commissions.forEach(commission => {
                    totalCommission += parseFloat(commission.percentage) / 100
                })
            }

            const grossMargin =
                item.salePrice2 -
                item.total2 -
                item.salePrice2 * totalCommission

            const margin = (grossMargin * 100) / item.salePrice2

            return margin.toFixed(2) + '%'
        },
        closeTaskDialog() {
            this.openTaskForm = false
            this.selectedTask = {}
        },
        openTaskDialog(item) {
            this.openTaskForm = true
            this.selectedTask = {
                quoteId: this.quote.id,
                cost: item.id + '/' + item.reference,
            }
        },
        async onMoveCallback(evt) {
            if (evt.moved) {
                const index = evt.moved.newIndex
                let beforeIndex = 0
                let afterIndex = 0
                if (index > 0) {
                    beforeIndex = this.costsOnTable[index - 1].index
                }

                if (index < this.costsOnTable.length - 1) {
                    afterIndex = this.costsOnTable[index + 1].index
                } else if (index == this.costsOnTable.length - 1) {
                    afterIndex = beforeIndex + 1
                }
                this.costsOnTable[index].index = (beforeIndex + afterIndex) / 2
                await API.updateCosts(this.quote.id, {
                    id: this.costsOnTable[index].id,
                    index: this.costsOnTable[index].index,
                })
                this.spreadCosts(_.cloneDeep(this.costsOnTable))
            }
        },
        openExchangeForm() {
            this.temporaryCurrency = _.cloneDeep(this.quote.currency)
            this.temporaryExchange = _.cloneDeep(this.quote.exchange)
            this.exchangeForm = true
        },
        async updateExchange() {
            this.quote.currency = this.temporaryCurrency
            this.quote.exchange = this.temporaryExchange
            await this.convertCost()
            this.exchangeForm = false
        },
        async replaceCosts(item) {
            try {
                this.categoryField = false
                this.loading = true
                this.error = false
                this.errorMsg = ''

                if (!item.category) {
                    this.cloneAlternate = _.cloneDeep(item)
                    this.categoryField = true
                } else {
                    this.createCostFromAlternative(item)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async createCostFromAlternative(item) {
            try {
                this.loading = true
                // Get the complete document as it comes from the database
                const selectedAlternative = _.cloneDeep(item)
                // Save the cost to be replaced
                let newCost = await API.approveAlternate({
                    quoteId: this.quote.id,
                    alternateId: item.id,
                })
                const index = this.costs.findIndex(cost =>
                    selectedAlternative.alternatives.includes(cost.id)
                )
                this.costs.splice(index, 1)
                this.costs.push(newCost)
                // Calculate total costs
                this.totalCosts = this.costs.reduce(
                    (acumulator, cost) => acumulator + cost.salePrice2,
                    0
                )
                // Allocate the total cost to the quotation
                this.quote.totalCosts = this.totalCosts
                // REPLACE COSTS
                this.costsOnTable = _.cloneDeep(this.costs)
                // REMOVE ALTERNATE
                this.currentAlternatives = this.currentAlternatives.filter(
                    a => a.id != item.id
                )
                this.setAlternatives(_.cloneDeep(this.currentAlternatives))
                this.replaceQuoteAction({
                    id: this.quote.id,
                    quote: this.quote,
                })
                this.replaceQuoteLocal(this.quote.id, { ...this.quote })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        calDifference(item) {
            const difference = item.salePrice2 - this.getItemsPrice(item)
            if (difference < 0) {
                return `DED ${new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: this.quote.currency,
                }).format(difference * -1)}`
            } else if (difference > 0) {
                return `ADD ${new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: this.quote.currency,
                }).format(difference)}`
            } else {
                return `${new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: this.quote.currency,
                }).format(difference)}`
            }
        },
        getItemsPrice(item) {
            const alternate = this.currentAlternatives.find(
                a => a.id == item.id
            )
            if (!alternate || alternate.alternatives.length == 0) {
                return 0
            }
            let total = 0
            for (let cost of this.costs) {
                if (alternate.alternatives.includes(cost.id)) {
                    total += cost.salePrice2
                }
            }
            return total
        },
        async deleteAlternative() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = ''
                await API.deleteCosts(this.quote.id, this.comToDelete.id)
                const copyAlternatives = _.cloneDeep(this.getAlternatives)
                const index = copyAlternatives.findIndex(
                    a => a.id == this.comToDelete.id
                )
                copyAlternatives.splice(index, 1)
                this.setAlternatives(_.cloneDeep(copyAlternatives))
                this.currentAlternatives = this.currentAlternatives.filter(
                    a => a.id != this.comToDelete.id
                )
                this.openDeleteDialog = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async updateAllAlternatives() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = ''
                for (let cost of this.getAlternatives) {
                    await API.updateCosts(this.quote.id, cost)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async updateAlternativeCosts() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = ''
                const index = this.currentAlternatives.findIndex(
                    a => a.id == this.costToEdit.id
                )
                this.currentAlternatives[index] = _.cloneDeep(this.costToEdit)
                delete this.costToEdit.createdOn
                delete this.costToEdit.updatedOn
                delete this.costToEdit.documentId
                await API.updateCosts(this.quote.id, this.costToEdit)
                this.setAlternatives(this.currentAlternatives)
                this.currentAlternatives = [...this.currentAlternatives]
                this.createDialogNewCost = false
                this.editDialog = false
                this.action = {
                    active: true,
                    type: 'success',
                    message: 'Action successfully completed',
                    color: 'primary',
                }
            } catch (error) {
                this.action = {
                    active: true,
                    type: 'error',
                    message: 'Error executing the action',
                    color: 'error',
                }
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeAddAlternative() {
            this.createDialogNewCost = false
            this.openAddAlternative = false
            this.editDialog = false
            this.copyItems = false
            this.alternatives = []
            this.alternative = false
            this.$refs.form.reset()
        },
        async saveAlternative(alternate) {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = ''
                const cost = await API.createAlternativeCosts(this.quote.id, {
                    alternatives:
                        typeof this.alternatives == 'string'
                            ? [this.alternatives]
                            : [],
                    ...alternate,
                })
                this.setAlternatives([cost, ...this.getAlternatives])
                cost.documentId = cost.id
                this.currentAlternatives = [cost, ...this.currentAlternatives]
                this.setTotal()
                await this.updateQuote()
                this.closeAddAlternative()
                this.action = {
                    active: true,
                    type: 'success',
                    message: 'Action successfully completed',
                    color: 'primary',
                }
            } catch (error) {
                this.action = {
                    active: true,
                    type: 'error',
                    message: 'Error executing the action',
                    color: 'error',
                }
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.costToEdit = null
                this.newItemCost = {
                    totalSections: [],
                    sections: [],
                    total: 0,
                    total2: 0,
                    totalMargin2: 0,
                    commission2: 0,
                    quantity2: 0,
                    salePrice2: 0,
                    pricePerQuantity2: 0,
                    margin: 40,
                    measure: 'ft2',
                    totalMargin: 0,
                    commission: 0,
                    salePrice: 0,
                    pricePerQuantity: 0,
                    american: {
                        tw: 0,
                        wa: 0,
                        cw: 0,
                        pw: 0,
                        ca: 0,
                    },
                    international: {
                        tw: 0,
                        wa: 0,
                        cw: 0,
                        pw: 0,
                        ca: 0,
                    },
                }
            }
        },
        joinItems(items) {
            const tempItems = []
            for (let item of items) {
                for (let section of item.sections) {
                    const index = tempItems.findIndex(
                        e => e.name == section.name
                    )
                    if (index < 0) {
                        tempItems.push(section)
                    } else {
                        for (let cost of section.items) {
                            tempItems[index].items.push(cost)
                        }
                    }
                }
            }
            return tempItems
        },
        async addAlternatives() {
            try {
                this.loading = true
                this.alternative = true
                this.quoteChangesAction(false)
                if (this.copyItems) {
                    const costsAlternatives = this.currentItems.find(
                        item => item.id == this.alternatives
                    )
                    const costSections = this.settings
                        .find(s => s.name === 'Quotes')
                        ?.costSections?.map(cs => cs.name)
                    this.costToEdit = _.cloneDeep(costsAlternatives)
                    delete this.costToEdit.id
                    delete this.costToEdit.index
                    delete this.costToEdit.type
                    delete this.costToEdit.createdOn
                    delete this.costToEdit.updatedOn
                    delete this.costToEdit.documentId
                    //////////////// clean data
                    this.costToEdit.sections = this.costToEdit.sections.filter(
                        s => costSections?.includes(s.name)
                    )
                    this.costToEdit.totalSections = this.costToEdit.totalSections.filter(
                        s => costSections?.includes(s.secName)
                    )
                    this.costToEdit.reference = this.alternativeName
                    this.originalCost = _.cloneDeep(this.costToEdit)
                    this.copyCost = false
                    this.editDialog = true
                } else {
                    this.newItemCost.reference = this.alternativeName
                    this.createDialogNewCost = true
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async createXLSX() {
            try {
                this.loading = true
                const data = []
                for (let cost of this.costs) {
                    for (let section of cost.sections) {
                        for (let item of section.items) {
                            const temp = {}
                            temp.Item = cost.reference
                            temp.Type = section.name
                            temp.TypeItem = item.name
                            temp.Weight = item.weight ? item.weight : 'none'
                            temp.Quantity = item.quantity
                            temp.Unit = item.unit
                            temp.Cost = item.cost
                            temp.Parcial = item.parcial.toFixed(2)
                            temp.Percentage = item.percentage
                            temp.TotalWeight = item.totalWeight.toFixed(2)
                            data.push(temp)
                        }
                    }
                }
                // only array possible
                var costsWS = XLSX.utils.json_to_sheet(data)

                // A workbook is the name given to an Excel file
                var wb = XLSX.utils.book_new() // make Workbook of Excel

                // add Worksheet to Workbook
                // Workbook contains one or more worksheets
                XLSX.utils.book_append_sheet(wb, costsWS, 'costs') // sheetAName is name of Worksheet

                // export Excel file
                XLSX.writeFile(wb, `${this.quote.name}.xlsx`) // name of the file is 'book.xlsx'
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openImportQuotes() {
            this.importDialog = true
        },
        closeImportQuotes() {
            this.importDialog = false
        },
        async importCosts(costs) {
            try {
                this.loading = true
                this.costs.push(...costs)
                this.costsOnTable = _.cloneDeep(this.costs)
                this.setTotal()
                await this.updateQuote()
                this.quoteChangesAction(true)
                this.convertCost()
                this.importDialog = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        makeid() {
            var length = 6
            var result = ''
            var characters =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            var charactersLength = characters.length
            for (var i = 0; i < length; i++) {
                result += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                )
            }
            return result.toUpperCase()
        },
        openDialogDelete(item) {
            this.comToDelete = item
            this.deleteCommissions = true
        },
        deleteCommission: async function(commissionDetail) {
            const index = this.quote.commissions.findIndex(
                u => u.details === commissionDetail
            )
            this.quote.commissions.splice(index, 1)
            this.deleteCommissions = false
            this.setTotalMargin(this.costs)
            if (
                this.currentAlternatives &&
                this.currentAlternatives.length > 0
            ) {
                this.setTotalMargin(this.currentAlternatives)
                await this.updateAllAlternatives()
            }
            for (const cost of this.costs) {
                this.costToEdit = _.cloneDeep(cost)
                await this.updateCosts()
            }
            await this.updateQuote()
            this.quoteChangesAction(false)
        },
        closeDialogDelete() {
            this.deleteCommissions = false
            this.commision = null
        },
        closeDialogCommissions() {
            this.dialogCommissions = false
        },
        addCommissions: async function(commission) {
            this.quote.commissions.push(commission)
            this.dialogCommissions = false
            if (this.costs && this.costs.length > 0) {
                this.setTotalMargin(this.costs)
            }
            if (
                this.currentAlternatives &&
                this.currentAlternatives.length > 0
            ) {
                this.setTotalMargin(this.currentAlternatives)
                await this.updateAllAlternatives()
            }
            for (const cost of this.costs) {
                this.costToEdit = _.cloneDeep(cost)
                await this.updateCosts()
            }
            await this.updateQuote()
            this.quoteChangesAction(false)
        },
        setTotalMargin(arr) {
            for (const cost of arr) {
                let sumCommissions = 0
                if (cost.total) {
                    if (this.quote.commissions) {
                        if (this.quote.commissions.length > 0) {
                            sumCommissions = 0
                            this.quote.commissions.forEach(x => {
                                sumCommissions += parseFloat(x.percentage)
                            })
                        }
                    }

                    const marginp = cost.margin / 100
                    const item1 = cost.total / (1 - marginp)
                    const item2 = item1 * (sumCommissions / 100)
                    const salesPrice1 = item1 + item2
                    const salesPrice = item1 + item2
                    let totalCommission = (
                        salesPrice *
                        (sumCommissions / 100)
                    ).toFixed(2)
                    cost.commission = parseFloat(totalCommission)
                    let salesPrice2 = salesPrice1.toFixed(2)
                    const margin = salesPrice - totalCommission - cost.total
                    let totalMargin = margin.toFixed(2)
                    cost.totalMargin = parseFloat(totalMargin)
                    cost.salePrice = parseFloat(salesPrice2)
                    cost.total2 = parseFloat(
                        (cost.total / Number(this.quote.exchange)).toFixed(2)
                    )
                    cost.totalMargin2 = parseFloat(
                        (
                            cost.totalMargin / Number(this.quote.exchange)
                        ).toFixed(2)
                    )
                    cost.salePrice2 = parseFloat(
                        (cost.salePrice / Number(this.quote.exchange)).toFixed(
                            2
                        )
                    )
                    cost.commission2 = parseFloat(
                        (cost.commission / Number(this.quote.exchange)).toFixed(
                            2
                        )
                    )
                    this.convertUnits(cost)
                    cost.pricePerQuantity = parseFloat(
                        (cost.salePrice / this.quantityInternational).toFixed(2)
                    )

                    cost.pricePerQuantity2 = parseFloat(
                        (cost.salePrice2 / cost.quantity2).toFixed(2)
                    )
                }
            }
        },
        openDialogCommissions() {
            if (!this.quote.commissions) {
                Object.defineProperty(this.quote, 'commissions', {
                    value: [],
                    writable: true,
                })
            }
            this.dialogCommissions = true
        },
        newCost() {
            if (this.quote.clientId) {
                this.quoteChangesAction(false)
                this.createDialogNewCost = true
                this.createCostForm = true
                this.error = false
                this.errorMsg = ''
            } else {
                this.errorMsg =
                    'The currency and exchange rate cannot be loaded because there is no client selected'
                this.setErrorItems(this.errorMsg)
            }
        },
        selectExchange(event) {
            const index = this.exchanges.findIndex(u => u.currency === event)
            if (index >= 0) {
                this.quote.exchange = parseFloat(this.exchanges[index].money)
                this.exchange = parseFloat(this.exchanges[index].money)
                this.quoteChangesAction(false)
            } else {
                this.error = true
                this.errorMsg = 'Check the exchange currency'
                this.quote.exchange = 1
                this.exchange = 1
            }
            if (this.costs.length > 0) {
                this.convertCost()
            }
        },
        closeDialog() {
            this.createDialogNewCost = false
            this.alternative = false
            this.costToEdit = {}
            this.editAlternative = false
            this.newItemCost = {
                totalSections: [],
                sections: [],
                total: 0,
                total2: 0,
                totalMargin2: 0,
                commission2: 0,
                quantity2: 0,
                salePrice2: 0,
                pricePerQuantity2: 0,
                margin: 40,
                measure: 'ft2',
                totalMargin: 0,
                commission: 0,
                salePrice: 0,
                pricePerQuantity: 0,
                american: {
                    tw: 0,
                    wa: 0,
                    cw: 0,
                    pw: 0,
                    ca: 0,
                },
                international: {
                    tw: 0,
                    wa: 0,
                    cw: 0,
                    pw: 0,
                    ca: 0,
                },
            }
        },
        closeEditItem() {
            this.editDialog = false
        },
        editItem(item) {
            if (
                this.getAlternatives.find(alternate => alternate.id == item.id)
            ) {
                this.editAlternative = true
            }
            this.costToEdit = _.cloneDeep(item)
            this.originalCost = _.cloneDeep(item)
            this.copyCost = false
            this.editDialog = true
        },
        copyItem(item) {
            const copyItem = _.cloneDeep(item)
            this.newItemCost = {
                american: copyItem.american,
                commission: copyItem.commission,
                commission2: copyItem.commission2,
                description: copyItem.description,
                descriptionCost: copyItem.descriptionCost,
                international: copyItem.international,
                margin: copyItem.margin,
                measure: copyItem.measure,
                pricePerQuantity: copyItem.pricePerQuantity,
                pricePerQuantity2: copyItem.pricePerQuantity2,
                quantity: copyItem.quantity,
                quantity2: copyItem.quantity2,
                reference: copyItem.reference,
                salePrice: copyItem.salePrice,
                salePrice2: copyItem.salePrice2,
                sections: copyItem.sections,
                total: copyItem.total,
                total2: copyItem.total2,
                totalMargin: copyItem.totalMargin,
                totalMargin2: copyItem.totalMargin2,
                totalSections: copyItem.totalSections,
            }
            this.createCostForm = true
            this.createDialogNewCost = true
        },
        copyTerms() {
            this.copyExchange = _.cloneDeep(this.quote.exchange)
            this.copyCurrency = _.cloneDeep(this.quote.currency)
        },
        deleteItem(item) {
            this.deleteDialog = true
            this.itemToDelete = item
        },
        async deleteCosts() {
            try {
                this.loading = true
                const index = this.costs.findIndex(
                    cost => cost.id == this.itemToDelete.id
                )
                this.costs.splice(index, 1)
                this.deleteDialog = false
                this.quoteChangesAction(false)
                await API.deleteCosts(this.quote.id, this.itemToDelete.id)
                this.setTotal()
                this.costsOnTable = _.cloneDeep(this.costs)
                this.spreadCosts(_.cloneDeep(this.costsOnTable))
                await this.updateQuote()
                this.quoteChangesAction(true)
                if (this.createTask) {
                    this.openTaskDialog(
                        this.costsOnTable[this.costsOnTable.length - 1]
                    )
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        ...mapActions([
            'replaceQuoteAction',
            'quoteChangesAction',
            'addContactsAction',
            'addUsersAction',
            'updateSeeMenuAction',
        ]),
        createCosts: async function() {
            try {
                this.loading = true
                delete this.newItemCost.index
                let cost
                cost = await API.createCosts(this.quote.id, this.newItemCost)
                this.costs.push(cost)
                this.costsOnTable = _.cloneDeep(this.costs)
                this.setTotal()
                await this.updateQuote()
                this.quoteChangesAction(true)
                if (this.createTask) {
                    this.openTaskDialog(
                        this.costsOnTable[this.costsOnTable.length - 1]
                    )
                }
                this.editDialog = false
                this.newItemCost = {
                    totalSections: [],
                    sections: [],
                    total: 0,
                    total2: 0,
                    totalMargin2: 0,
                    commission2: 0,
                    quantity2: 0,
                    salePrice2: 0,
                    pricePerQuantity2: 0,
                    margin: 40,
                    measure: 'ft2',
                    totalMargin: 0,
                    commission: 0,
                    salePrice: 0,
                    pricePerQuantity: 0,
                    american: {
                        tw: 0,
                        wa: 0,
                        cw: 0,
                        pw: 0,
                        ca: 0,
                    },
                    international: {
                        tw: 0,
                        wa: 0,
                        cw: 0,
                        pw: 0,
                        ca: 0,
                    },
                }
                this.createDialogNewCost = false
                this.action = {
                    active: true,
                    type: 'success',
                    message: 'Action successfully completed',
                    color: 'primary',
                }
            } catch (error) {
                this.action = {
                    active: true,
                    type: 'error',
                    message: 'Error executing the action',
                    color: 'error',
                }
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
                return error
            } finally {
                this.loading = false
            }
        },
        updateCosts: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                delete this.costToEdit.createdOn
                delete this.costToEdit.updatedOn
                delete this.costToEdit.documentId
                const cost = await API.updateCosts(this.quote.id, {
                    ...this.costToEdit,
                    ...(this.costToEdit.engineeringDate &&
                    typeof this.costToEdit.engineeringDate == 'object'
                        ? {
                              engineeringDate: moment
                                  .unix(
                                      this.costToEdit.engineeringDate
                                          ._seconds ||
                                          this.costToEdit.engineeringDate
                                              .seconds
                                  )
                                  .format('YYYY-MM-DD'),
                          }
                        : {}),
                })
                if (cost && cost.updatedOn) {
                    this.quote.updatedOn = moment
                        .unix(cost.updatedOn._seconds)
                        .format('MMM/DD/YYYY h:mm a')
                }
                const index = this.costs.findIndex(c => cost.id == c.id)
                if (index >= 0) {
                    this.costs.splice(index, 1, { ...this.costToEdit, ...cost })
                    this.costsOnTable.splice(index, 1, {
                        ...this.costToEdit,
                        ...cost,
                    })
                }
                this.setTotal()
                await this.updateQuote()
                this.quoteChangesAction(true)
                if (this.createTask) {
                    this.openTaskDialog(
                        this.costsOnTable[this.costsOnTable.length - 1]
                    )
                }
                this.createDialogNewCost = false
                this.editDialog = false
                this.newItemCost = {
                    totalSections: [],
                    sections: [],
                    total: 0,
                    total2: 0,
                    totalMargin2: 0,
                    commission2: 0,
                    quantity2: 0,
                    salePrice2: 0,
                    pricePerQuantity2: 0,
                    margin: 40,
                    measure: 'ft2',
                    totalMargin: 0,
                    commission: 0,
                    salePrice: 0,
                    pricePerQuantity: 0,
                    american: {
                        tw: 0,
                        wa: 0,
                        cw: 0,
                        pw: 0,
                        ca: 0,
                    },
                    international: {
                        tw: 0,
                        wa: 0,
                        cw: 0,
                        pw: 0,
                        ca: 0,
                    },
                }
                this.action = {
                    active: true,
                    type: 'success',
                    message: 'Action successfully completed',
                    color: 'primary',
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.spreadCosts(_.cloneDeep(this.costsOnTable))
                this.loading = false
            }
        },
        replaceQuote(id, quote) {
            this.replaceQuoteAction({ id, quote })
        },
        updateQuote: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id } = this.quote
                let changes = { commissions: this.quote.commissions }
                for (const key in this.quote) {
                    if (
                        key !== 'clients' &&
                        key != 'client' &&
                        ((this.oldQuote[key] === undefined &&
                            this.quote[key] !== undefined) ||
                            (this.quote[key] !== undefined &&
                                JSON.stringify(this.quote[key]) !==
                                    JSON.stringify(this.oldQuote[key])))
                    ) {
                        changes[key] =
                            key === 'city'
                                ? this.quote[key].text
                                : this.quote[key]
                    }
                }
                const {
                    data: { quote },
                } = await API.updateQuote(id, {
                    notificationId: 'LrubMkCSfpNwEoXemQKL',
                    ...changes,
                })
                this.oldQuote = Object.assign({}, this.quote)
                quote.createdOn = moment
                    .unix(quote.createdOn._seconds)
                    .format('MMM/DD/YYYY')
                quote.updatedOn = moment
                    .unix(quote.updatedOn._seconds)
                    .format('MMM/DD/YYYY')
                quote.users = this.quote.users
                quote.clients = this.quote.clients
                quote.client = this.quote.client
                quote.mainContact = this.quote.mainContact
                this.replaceQuoteAction({ id, quote })
                this.replaceQuoteLocal(id, { ...this.quote, ...quote })
                // function provided from the mainboard
                this.filterQuotes()
                //
                this.quoteChangesAction(true)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.deleteDialog = false
            }
        },
        setTotal(element = null) {
            if (!element) {
                this.totalCosts = 0
                if (this.costs.length > 0) {
                    this.costs.forEach(element => {
                        this.totalCosts += element.salePrice2
                    })
                }
                this.quote.totalCosts = this.totalCosts
            }
        },
        convertions(arr) {
            if (Number(this.quote.exchange) !== this.copyExchange) {
                arr.forEach(element => {
                    if (
                        this.quote.currency ==
                            this.settingExchange.globalCurrency &&
                        this.quote.exchange == 1
                    ) {
                        element.salePrice2 = element.salePrice
                        element.commission2 = element.commission
                        element.pricePerQuantity2 = element.pricePerQuantity
                        element.total2 = element.total
                        element.totalMargin2 = element.totalMargin
                    } else {
                        element.salePrice2 = parseFloat(
                            (
                                element.salePrice / Number(this.quote.exchange)
                            ).toFixed(2)
                        )
                        element.commission2 = parseFloat(
                            (
                                element.commission / Number(this.quote.exchange)
                            ).toFixed(2)
                        )
                        element.total2 = parseFloat(
                            (
                                element.total / Number(this.quote.exchange)
                            ).toFixed(2)
                        )
                        element.totalMargin2 = parseFloat(
                            (
                                element.totalMargin /
                                Number(this.quote.exchange)
                            ).toFixed(2)
                        )
                        element.pricePerQuantity2 = parseFloat(
                            (element.salePrice2 / element.quantity2).toFixed(2)
                        )
                    }
                    this.convertUnits(element)
                    this.convertAmericanStatistics(element)
                    this.convertInternationalStatistics(element)
                })
            } else {
                arr.forEach(element => {
                    element.salePrice2 = parseFloat(
                        (
                            element.salePrice / Number(this.quote.exchange)
                        ).toFixed(2)
                    )
                    element.commission2 = parseFloat(
                        (
                            element.commission / Number(this.quote.exchange)
                        ).toFixed(2)
                    )
                    element.total2 = parseFloat(
                        (element.total / Number(this.quote.exchange)).toFixed(2)
                    )
                    element.totalMargin2 = parseFloat(
                        (
                            element.totalMargin / Number(this.quote.exchange)
                        ).toFixed(2)
                    )
                    this.convertUnits(element)
                    element.pricePerQuantity2 = parseFloat(
                        (element.salePrice2 / element.quantity2).toFixed(2)
                    )
                    this.convertAmericanStatistics(element)
                    this.convertInternationalStatistics(element)
                })
            }
        },
        async convertCost() {
            this.convertions(this.costs)
            this.convertions(this.currentAlternatives)
            if (
                !this.history &&
                this.quote.status !== 'SENT' &&
                !this.isMounted
            ) {
                if (this.costs && this.costs.length > 0) {
                    for (const cost of this.costs) {
                        this.costToEdit = _.cloneDeep(cost)
                        await this.updateCosts()
                    }
                }
                if (
                    this.currentAlternatives &&
                    this.currentAlternatives.length > 0
                ) {
                    await this.updateAllAlternatives()
                }
            }
        },
        convertAmericanStatistics(element = null) {
            const settingUnits = this.settings.find(u => u.name === 'Quotes')
            if (!element) {
                this.costs.forEach(element => {
                    if (element.american.tw > 0) {
                        element.american.cw = parseFloat(
                            (element.total2 / element.american.tw).toFixed(2)
                        )
                        element.american.pw = parseFloat(
                            (element.salePrice2 / element.american.tw).toFixed(
                                2
                            )
                        )
                    }
                    if (element.quantity > 0) {
                        if (element.american.tw > 0) {
                            //The unit weight is 0 if the unit of measure is not consistent for the calculation.
                            if (
                                settingUnits.measureUnits.find(
                                    unit =>
                                        unit.abbr == element.measure &&
                                        (unit.measure == 'time' ||
                                            unit.measure == 'mass')
                                )
                            ) {
                                element.american.wa = 0
                            } else {
                                element.american.wa = parseFloat(
                                    (
                                        element.american.tw / element.quantity2
                                    ).toFixed(2)
                                )
                            }
                        }
                        element.american.ca = parseFloat(
                            (element.total2 / element.quantity2).toFixed(2)
                        )
                    }
                    if (element.american.tw === 0) {
                        element.american.wa = 0
                        element.american.cw = 0
                        element.american.pw = 0
                    }
                })
            } else {
                if (element.american.tw > 0) {
                    element.american.cw = parseFloat(
                        (element.total2 / element.american.tw).toFixed(2)
                    )
                    element.american.pw = parseFloat(
                        (element.salePrice2 / element.american.tw).toFixed(2)
                    )
                }
                if (element.quantity > 0) {
                    if (element.american.tw > 0) {
                        //The unit weight is 0 if the unit of measure is not consistent for the calculation.
                        if (
                            settingUnits.measureUnits.find(
                                unit =>
                                    unit.abbr == element.measure &&
                                    (unit.measure == 'time' ||
                                        unit.measure == 'mass')
                            )
                        ) {
                            element.american.wa = 0
                        } else {
                            element.american.wa = parseFloat(
                                (
                                    element.american.tw / element.quantity2
                                ).toFixed(2)
                            )
                        }
                    }
                    element.american.ca = parseFloat(
                        (element.total2 / element.quantity2).toFixed(2)
                    )
                }
                if (element.american.tw === 0) {
                    element.american.wa = 0
                    element.american.cw = 0
                    element.american.pw = 0
                }
            }
        },
        convertInternationalStatistics(element = null) {
            const settingUnits = this.settings.find(u => u.name === 'Quotes')
            if (!element) {
                this.costs.forEach(element => {
                    if (element.international.tw > 0) {
                        element.international.cw = parseFloat(
                            (element.total / element.international.tw).toFixed(
                                2
                            )
                        )
                        element.international.pw = parseFloat(
                            (
                                element.salePrice / element.international.tw
                            ).toFixed(2)
                        )
                    }
                    if (element.quantity > 0) {
                        if (element.international.tw > 0) {
                            //The unit weight is 0 if the unit of measure is not consistent for the calculation.
                            if (
                                settingUnits.measureUnits.find(
                                    unit =>
                                        unit.abbr == element.measure &&
                                        (unit.measure == 'time' ||
                                            unit.measure == 'mass')
                                )
                            ) {
                                element.international.wa = 0
                            } else {
                                element.international.wa = parseFloat(
                                    (
                                        element.international.tw /
                                        this.quantityInternational
                                    ).toFixed(2)
                                )
                            }
                        }
                        element.international.ca = parseFloat(
                            (
                                element.total / this.quantityInternational
                            ).toFixed(2)
                        )
                    }

                    if (element.international.tw === 0) {
                        element.international.wa = 0
                        element.international.cw = 0
                        element.international.pw = 0
                    }
                })
            } else {
                if (element.international.tw > 0) {
                    element.international.cw = parseFloat(
                        (element.total / element.international.tw).toFixed(2)
                    )
                    element.international.pw = parseFloat(
                        (element.salePrice / element.international.tw).toFixed(
                            2
                        )
                    )
                }
                if (element.quantity > 0) {
                    if (element.international.tw > 0) {
                        //The unit weight is 0 if the unit of measure is not consistent for the calculation.
                        if (
                            settingUnits.measureUnits.find(
                                unit =>
                                    unit.abbr == element.measure &&
                                    (unit.measure == 'time' ||
                                        unit.measure == 'mass')
                            )
                        ) {
                            element.international.wa = 0
                        } else {
                            element.international.wa = parseFloat(
                                (
                                    element.international.tw /
                                    this.quantityInternational
                                ).toFixed(2)
                            )
                        }
                    }
                    element.international.ca = parseFloat(
                        (element.total / this.quantityInternational).toFixed(2)
                    )
                }

                if (element.international.tw === 0) {
                    element.international.wa = 0
                    element.international.cw = 0
                    element.international.pw = 0
                }
            }
        },
        setSettings() {
            const indexExchanges = this.settings.findIndex(
                u => u.name === 'Exchange'
            )
            this.settingExchange = this.settings[indexExchanges]
            this.exchanges = this.settingExchange.exchanges
            this.globalCurrency = this.settingExchange.globalCurrency
            this.exchanges.forEach(v => this.itemsCurrency.push(v.currency))
            this.itemsCurrency.push(this.globalCurrency)
            if (
                this.quote.clientId === null ||
                this.quote.clientId === undefined
            ) {
                this.error = true
                this.errorMsg =
                    'The currency and exchange rate cannot be loaded because there is no client selected'
                this.quote.exchange = 1
                this.exchange = 1
            }
            if (this.costs && this.costs.length > 0) {
                this.newCommissions()
                this.setTotal()
            }
            this.costsOnTable = this.costs
            this.costsOnTable.sort((a, b) => {
                return a.index - b.index
            })
        },
        newCommissions() {
            if (this.quote.commissions) {
                if (this.quote.commissions.length > 0) {
                    this.sumCommissions = 0
                    this.quote.commissions.forEach(x => {
                        this.sumCommissions += parseFloat(x.percentage)
                    })
                }
            }
            this.costs.forEach(element => {
                const marginp = element.margin / 100

                const item1 = element.total / (1 - marginp)

                const item2 = item1 * (this.sumCommissions / 100)

                const salesPrice1 = item1 + item2
                const salesPrice = item1 + item2
                const margin = salesPrice * (element.margin / 100)
                const totalMargin = margin.toFixed(2)
                element.totalMargin = parseFloat(totalMargin)
                const totalCommission = (
                    salesPrice *
                    (this.sumCommissions / 100)
                ).toFixed(2)
                element.commission = parseFloat(totalCommission)
                let salesPrice2 = salesPrice1.toFixed(2)
                element.salePrice = parseFloat(salesPrice2)
                this.convertUnits(element)
                const pricePerQ = element.salePrice / this.quantityInternational
                element.pricePerQuantity = parseFloat(pricePerQ.toFixed(2))
            })
            this.convertCost()
        },

        async getContacts() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const {
                    data: { contacts },
                } = await API.getContacts(this.companyId)
                for (const contact of contacts) {
                    if (contact.picture) {
                        const path = `${this.companyId}/${this.folderClientsPictures}`
                        const storageRef = storage().ref(path)
                        const profilePicRef = storageRef.child(contact.picture)
                        await profilePicRef
                            .getDownloadURL()
                            .then(url => {
                                contact.profilePicURL = url
                            })
                            .catch(error => {
                                contact.profilePicRef = ''
                                console.log(error)
                            })
                    }
                }
                this.addContactsAction(contacts)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },

        getUsers: async function() {
            try {
                this.error = false
                this.errorMsg = null
                this.loading = true
                const {
                    data: { users },
                } = await API.getUsers(this.companyId)
                for (const user of users) {
                    if (user.picture) {
                        const path = `${this.companyId}/${this.folderUsersPictures}`
                        const storageRef = storage().ref(path)
                        const profilePicRef = storageRef.child(user.picture)
                        await profilePicRef
                            .getDownloadURL()
                            .then(url => {
                                user.profilePicURL = url
                            })
                            .catch(error => {
                                user.profilePicRef = ''
                                console.log(error)
                            })
                    }
                    user.createdOn = moment
                        .unix(user.createdOn._seconds)
                        .format('MMM/DD/YYYY HH:mm')
                }
                this.addUsersAction(users)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },

        convertUnits(element) {
            const settingUnits = this.settings.find(u => u.name === 'Quotes')

            if (element.measure == 'qt' || element.measure == 'QT') {
                element.quantity2 = element.quantity
                this.quantityInternational = element.quantity
            } else if (
                settingUnits.measureUnits.find(
                    unit =>
                        unit.abbr == element.measure && unit.measure == 'time'
                )
            ) {
                element.quantity2 = element.quantity
                this.quantityInternational = element.quantity
            } else if (
                settingUnits.measureUnits.find(
                    unit =>
                        unit.abbr == element.measure && unit.measure == 'mass'
                )
            ) {
                element.quantity2 = element.quantity
                this.quantityInternational = element.quantity
            } else if (element.measure == 'yd2') {
                element.quantity2 = parseFloat(
                    (element.quantity / 0.111111).toFixed(2)
                )
                this.quantityInternational = parseFloat(
                    (element.quantity / 1.19599).toFixed(2)
                )
            } else if (
                settingUnits.measureUnits.find(unit => {
                    if (
                        unit.abbr == element.measure &&
                        unit.measure == 'length'
                    ) {
                        return true
                    }
                })
            ) {
                element.quantity2 = parseFloat(
                    convert(element.quantity)
                        .from(element.measure)
                        .to('ft')
                )
                this.quantityInternational = parseFloat(
                    convert(element.quantity)
                        .from(element.measure)
                        .to('m')
                )
            } else {
                element.quantity2 = parseFloat(
                    convert(element.quantity)
                        .from(element.measure)
                        .to('ft2')
                )
                this.quantityInternational = parseFloat(
                    convert(element.quantity)
                        .from(element.measure)
                        .to('m2')
                )
            }
        },
    },
    async mounted() {
        this.updateSeeMenuAction(false)
        this.isMounted = true
        if (this.settings) {
            this.setSettings()
        } else {
            const {
                data: { settings },
            } = await API.getSettings()
            const indexExchanges = settings.findIndex(
                u => u.name === 'Exchange'
            )
            this.settingExchange = settings[indexExchanges]
            this.exchanges = this.settingExchange.exchanges
            this.globalCurrency = this.settingExchange.globalCurrency
            this.exchanges.forEach(v => this.itemsCurrency.push(v.currency))
            this.itemsCurrency.push(this.globalCurrency)
        }
        const invoiceSettings = this.settings.find(
            setting => setting.name.toLowerCase() == 'invoicing'
        )
        this.categories = invoiceSettings.categories
        if (this.quote) {
            this.copyTerms()
        }
        for (let alternative of this.getAlternatives) {
            alternative.documentId = alternative.id
            this.currentAlternatives.push(alternative)
        }
        this.currentAlternatives.sort(
            (a, b) => a.createdOn._seconds - b.createdOn._seconds
        )
        this.isMounted = false
        const {
            data: { user },
        } = await API.getMyInfo()
        const createTask = user.permissions.find(x => x == 'createTask')
        if (createTask) {
            this.createTask = true
        }
        this.updateSeeMenuAction(true)
    },
}
</script>

<style scoped>
.v-data-table >>> table {
    border: thin solid #eeeeee;
}
.no-border {
    border: none !important;
}
tr:not(:last-child) > td.border-grey {
    border-bottom: thin solid #eeeeee !important;
}
tr:hover {
    background: unset !important;
}
th,
td {
    font-size: 0.875rem !important;
}
.invisible {
    visibility: hidden;
}
.costs-table-footer {
    background-color: #eee;
}

.costs-table-footer:hover {
    background-color: #eee;
}
</style>
